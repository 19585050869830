import React from 'react';
import { Link } from 'react-router-dom';

const HomeTable = ({ networks }) => {
    return (
        <div className="card w-full bg-base-100 shadow-xl border border-primary my-7">
            <div className="card-body">
                <div className="overflow-x-auto">
                    <table className="table table-sm w-full custom-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Chain ID</th>
                                <th>Lastest Block</th>
                                <th>Age</th>
                            </tr>
                        </thead>
                        <tbody>
                            {networks.map((network) => (
                                <React.Fragment key={network.chainId}>
                                    <tr>
                                        <th>
                                            <Link to={`/chain/${network.chainId}`} className="btn btn-sm btn-ghost">
                                                <div className="w-7 rounded">
                                                    <img
                                                        src={network.logoURI}
                                                        alt={network.chainName}
                                                        className="rounded pr-2"
                                                    />
                                                </div>
                                                {network.chainName}
                                            </Link>
                                        </th>
                                        <td>{network.chainId}</td>
                                        <td><Link to={`/block/${network.chainId}/${network.best_block}`} className='link link-info link-hover'>{network.best_block || 0}</Link></td>
                                        <td>
                                            {network.block_time_ago > 900 ?
                                                <div className="tooltip" data-tip={network.block_time}>
                                                    <div className="badge badge-xs badge-warning"></div>
                                                </div>
                                                :
                                                <div className="tooltip" data-tip={network.block_time}>
                                                    <div className="badge badge-xs badge-success"></div>
                                                </div>
                                            }
                                        </td>
                                    </tr >
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
};

export default HomeTable;
