import React from 'react';
import ReactDOM from 'react-dom';

const CustomModal = ({ modalId, title, message, buttonText, isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <input type="checkbox" id={modalId} className="modal-toggle" checked={isOpen} readOnly />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{title}</h3>
          <p className="py-4">{message}</p>
          <div className="modal-action">
            <label htmlFor={modalId} className="btn" onClick={onClose}>{buttonText}</label>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default CustomModal;
