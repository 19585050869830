import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { RiGasStationLine } from 'react-icons/ri';
import { RxTimer } from 'react-icons/rx';
import { IoCubeOutline } from 'react-icons/io5';
import { BiTransferAlt } from 'react-icons/bi';
import { TfiWorld } from 'react-icons/tfi';
import axios from "axios";
import { PropagateLoader } from 'react-spinners';
import "./ChainInfo.css";

function ChainInfo() {
    function truncateHash(hash, length = 8) {
        const front = hash.substring(0, length);
        const back = hash.substring(hash.length - length);
        return `${front}...${back}`;
    }
    const { chainId } = useParams();
    const [networks, setNetworks] = useState([]);
    const [prices, setPrices] = useState([]);
    const [gasPrice, setGasPrice] = useState(0);
    const [loading, setLoading] = useState(true);
    const [recent, setRecent] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const prevRecentRef = useRef({});
    const prevRecent = prevRecentRef.current;

    const fetchData = () => {
        Promise.all([
            axios.get('/api/networks'),
            axios.get('/api/getprices'),
            axios.get(`/api/getgasprice/${chainId}`),
            axios.get(`/api/getrecent/${chainId}`)
        ]).then((responses) => {
            const networksResponse = responses[0];
            const pricesResponse = responses[1];
            const gasPriceResponse = responses[2];
            const recentResponse = responses[3];

            setNetworks(networksResponse.data);
            setPrices(pricesResponse.data);
            setGasPrice(gasPriceResponse.data.result);
            setRecent(recentResponse.data);
            prevRecentRef.current = recent;
            setLoading(false);
            if (initialLoad) setInitialLoad(false);
        }).catch((error) => {
            console.error(`There was an error retrieving the data: ${error}`);
        });
    };

    const isNewItem = (item, type) => {
        if (!prevRecent[type]) return true;
        return !prevRecent[type].some(prevItem => prevItem.id === item.id);
    };


    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 10000);

        return () => clearInterval(interval);
    }, [chainId]);

    const networksWithPrices = networks.map(network => {
        const priceInfo = prices.find(price => price.id === network.nativeCurrency.geckoId);
        return { ...network, ...priceInfo };
    });
    const currentNetwork = networks.find(network => network.chainId === parseInt(chainId));
    const currentPrice = prices.find(price => price.id === currentNetwork?.nativeCurrency.geckoId);

    useEffect(() => {
        if (!initialLoad && networks.length > 0) {
            const currentNetwork = networks.find(network => network.chainId === parseInt(chainId));
            if (currentNetwork) {
                document.title = "vfat scan - " + currentNetwork.chainName;
            }
        }
    }, [networks, chainId, initialLoad]);

    const truncateTxHash = (txHash) => {
        return txHash.slice(0, 4) + "..." + txHash.slice(-4);
    };

    const findPrice = (id) => {
        return prices.find(price => price.id === id);
    }

    return (
        <>
            {loading && (
                <div className="grid h-screen place-items-center">
                    <PropagateLoader color="#f1fa89" />
                </div>
            )}
            {!loading && (
                <>
                    <div className="stats stats-vertical lg:stats-horizontal shadow w-full mt-7 border border-primary">
                        {currentNetwork && currentPrice && prices && (
                            <>
                                {currentNetwork.chainName === currentNetwork.nativeCurrency.name && currentNetwork.isLayer2 === false && (
                                    <>
                                        <div className="stat">
                                            <div className="stat-title">
                                                <div className="flex">
                                                    <img src={currentNetwork.logoURI} alt={currentNetwork.chainName} className="w-7 h-7 rounded" />&nbsp;{currentNetwork.chainName}&nbsp;({findPrice(currentNetwork.nativeCurrency.geckoId).symbol.toUpperCase()})&nbsp;Price

                                                </div>
                                            </div>
                                            <div className="stat-value">${currentPrice?.price?.toLocaleString()}</div>
                                        </div>
                                        <div className="stat">
                                            <div className="stat-title"><div className="flex"><TfiWorld className="mt-1" />&nbsp;Market Cap</div></div>
                                            <div className="stat-value">
                                                {currentPrice?.market_cap === 0 ? "-" : `$${findPrice(currentNetwork.nativeCurrency.geckoId).market_cap.toLocaleString()}`}
                                            </div>
                                        </div>
                                        {recent.blocks && recent.blocks.result && (
                                            <div className="stat">
                                                <div className="stat-title"><div className="flex"><RxTimer className="mt-1" />&nbsp;Last Block&nbsp; {currentNetwork.block_time_ago > 900 ?
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-warning"></div>
                                                    </div>
                                                    :
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-success"></div>
                                                    </div>
                                                }</div></div>
                                                <div className="stat-value">{recent.blocks.result[0].block_number}</div>
                                            </div>

                                        )}
                                    </>
                                )}
                                {currentNetwork.chainName !== currentNetwork.nativeCurrency.name && currentNetwork.isLayer2 === true && currentNetwork.layer2Info.geckoId !== currentNetwork.nativeCurrency.geckoId && (
                                    <>
                                        <div className="stat">
                                            <div className="stat-title">
                                                <div className="flex">
                                                    <img src={currentNetwork.logoURI} alt={currentNetwork.chainName} className="w-7 h-7 rounded" />&nbsp;{currentNetwork.chainName}&nbsp;({findPrice(currentNetwork.layer2Info.geckoId).symbol.toUpperCase()})&nbsp;Price
                                                </div>
                                            </div>
                                            <div className="stat-value">${findPrice(currentNetwork.layer2Info.geckoId).price.toLocaleString()}</div>
                                        </div>
                                        <div className="stat">
                                            <div className="stat-title"><div className="flex"><TfiWorld className="mt-1" />&nbsp;Market Cap</div></div>
                                            <div className="stat-value">
                                                {currentPrice?.market_cap === 0 ? "-" : `$${findPrice(currentNetwork.layer2Info.geckoId).market_cap.toLocaleString()}`}
                                            </div>
                                        </div>
                                        {recent.blocks && recent.blocks.result && (
                                            <div className="stat">
                                                <div className="stat-title"><div className="flex"><RxTimer className="mt-1" />&nbsp;Last Block&nbsp; {currentNetwork.block_time_ago > 900 ?
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-warning"></div>
                                                    </div>
                                                    :
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-success"></div>
                                                    </div>
                                                }</div></div>
                                                <div className="stat-value">{recent.blocks.result[0].block_number}</div>
                                            </div>

                                        )}
                                        <div className="stat">
                                            <div className="stat-title">
                                                <div className="flex">Gas Token Price&nbsp;({findPrice(currentNetwork.nativeCurrency.geckoId).symbol.toUpperCase()})</div>
                                            </div>
                                            <div className="stat-value">${findPrice(currentNetwork.nativeCurrency.geckoId).price.toLocaleString()}</div>
                                        </div>
                                    </>
                                )}
                                {currentNetwork.chainName !== currentNetwork.nativeCurrency.name && currentNetwork.isLayer2 === true && currentNetwork.layer2Info.geckoId === currentNetwork.nativeCurrency.geckoId && (
                                    <>
                                        <div className="stat">
                                            <div className="stat-title">
                                                <div className="flex">
                                                    <img src={currentNetwork.logoURI} alt={currentNetwork.chainName} className="w-7 h-7 rounded" />&nbsp;{currentNetwork.chainName}
                                                </div>
                                            </div>
                                            <div className="stat-value">-</div>
                                        </div>
                                        {recent.blocks && recent.blocks.result && (
                                            <div className="stat">
                                                <div className="stat-title"><div className="flex"><RxTimer className="mt-1" />&nbsp;Last Block&nbsp; {currentNetwork.block_time_ago > 900 ?
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-warning"></div>
                                                    </div>
                                                    :
                                                    <div className="tooltip tooltip-right" data-tip={currentNetwork.block_time}>
                                                        <div className="badge badge-xs badge-success"></div>
                                                    </div>
                                                }</div></div>
                                                <div className="stat-value">{recent.blocks.result[0].block_number}</div>
                                            </div>

                                        )}
                                        <div className="stat">
                                            <div className="stat-title">
                                                <div className="flex">Gas Token Price&nbsp;({findPrice(currentNetwork.nativeCurrency.geckoId).symbol.toUpperCase()})</div>
                                            </div>
                                            <div className="stat-value">${findPrice(currentNetwork.nativeCurrency.geckoId).price.toLocaleString()}</div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {(gasPrice !== undefined && gasPrice !== null) && (
                            <div className="stat">
                                <div className="stat-title">
                                    <div className="flex">
                                        <RiGasStationLine className="mt-1" />&nbsp;Med Gas Price
                                    </div>
                                </div>
                                <div className="stat-value">{gasPrice || 0}&nbsp;Gwei</div>
                            </div>
                        )}

                    </div>

                    <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 w-full mt-5 mb-5">
                        <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl">
                            <div className="card-body">
                                <h2 className="card-title"><IoCubeOutline />Latest Blocks</h2>
                                <div className="divider"></div>
                                {recent.blocks && recent.txs && recent.blocks.result.map(block => (
                                    <div className={`fade-in ${isNewItem(block, 'blocks') ? 'new-item' : ''}`} key={block.block_hash}>
                                        <div className="flex flex-col lg:flex-row space-y-0 lg:space-y-0 lg:space-x-4">
                                            <div className="flex flex-col flex-grow">
                                                <p>Block Number: <Link
                                                    className="link link-info link-hover"
                                                    to={`/block/${chainId}/${block.block_number}`}
                                                >{block.block_number}</Link></p>
                                                <p>Block Hash: <Link
                                                    className="link link-info link-hover"
                                                    to={`/block/${chainId}/${block.block_number}`}
                                                >{truncateHash(block.block_hash)}</Link></p>
                                                <p>Time: {block.block_timestamp}</p>
                                            </div>
                                            <div className="hidden lg:block w-px bg-gray-400 mx-4"></div>
                                            <div className="flex flex-col flex-grow">
                                                <p>Total Transactions: <Link
                                                    className="link link-info link-hover"
                                                    to={`/block/${chainId}/${block.block_number}#txs`}
                                                >{block.transaction_count}</Link></p>
                                                <p>Total Logs: <Link
                                                    className="link link-info link-hover"
                                                    to={`/block/${chainId}/${block.block_number}#logs`}
                                                >{block.log_count}</Link></p>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                    </div>
                                ))}

                            </div>
                        </div>

                        <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl">
                            <div className="card-body">
                                <h2 className="card-title"><BiTransferAlt />Latest Transactions</h2>
                                <div className="divider"></div>
                                {recent.txs && recent.txs.map(tx => (
                                    <div className={`fade-in ${isNewItem(tx, 'txs') ? 'new-item' : ''}`} key={tx.transaction_hash}>
                                        <div className="flex flex-col lg:flex-row space-y-0 lg:space-y-0 lg:space-x-4">
                                            <div className="flex flex-col flex-grow">
                                                <p>Block Number: <Link
                                                    className="link link-info link-hover"
                                                    to={`/block/${chainId}/${tx.block_number}`}
                                                > {tx.block_number}</Link></p>
                                                <p>Tx Hash: <Link
                                                    className="link link-info link-hover"
                                                    to={`/tx/${chainId}/${tx.transaction_hash}`}>{truncateTxHash(tx.transaction_hash)}</Link></p>
                                                <p>Total Logs: <Link
                                                    className="link link-info link-hover"
                                                    to={`/tx/${chainId}/${tx.transaction_hash}#logs`}
                                                > {tx.log_count}</Link></p>
                                            </div>
                                            <div className="hidden lg:block w-px bg-gray-400 mx-4"></div>
                                            <div className="flex flex-col flex-grow">
                                                <p>From: <Link
                                                    className="link link-info link-hover"
                                                    to={`/address/${chainId}/${tx.from_address}`}
                                                >{truncateHash(tx.from_address)}</Link></p>
                                                <p>
                                                    To:
                                                    {tx.to_address === '0x' ? (
                                                        <span> Contract Creation</span>
                                                    ) : (
                                                        <Link
                                                            className="link link-info link-hover"
                                                            to={`/address/${chainId}/${tx.to_address}`}
                                                        >
                                                            {truncateHash(tx.to_address)}
                                                        </Link>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                    </div>
                                ))}
                                {recent.message &&
                                    <div className="text-center">{recent.message}</div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
            }

        </>
    );
}
export default ChainInfo;
