import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TbSquareRoundedLetterT } from 'react-icons/tb';

const Portfolio = ({ tokens, chainId }) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const truncateTxHash = (txHash) => {
        return isLargeScreen ? txHash : txHash.slice(0, 4) + "..." + txHash.slice(-4);
    };

    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-pin-cols table-zebra table-sm w-full font-primary">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <td>Name</td>
                        <td>Decimals</td>
                        <td>Contract</td>
                        <td>Amount</td>
                        {/*<td>USD Value</td>*/}
                        <td>Verified</td>
                    </tr>
                </thead>
                <tbody>
                    {tokens && tokens.length === 0 ?
                        <tr>
                            <td colSpan="4" className="text-center">
                                No tokens found
                            </td>
                        </tr>
                        :
                        tokens.map((token, index) => (
                            <tr key={index}>
                                <th className='min-w-[12rem]'>
                                    {!token.logo || token.logo === null ? <TbSquareRoundedLetterT className="w-6 h-6 mr-2 inline-block rounded-full" /> : <img src={token.logo} alt={token.name} className="w-6 h-6 mr-2 inline-block rounded-full" />}
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/token/${chainId}/${token.full_symbol}/${token.token_address}`}
                                    >
                                        {token.symbol}
                                    </Link>
                                </th>
                                <td>{token.name}</td>
                                <td>{token.decimals}</td>
                                <td>
                                    <Link className="link link-info link-hover" to={`/address/${chainId}/${token.token_address}`}>
                                        {truncateTxHash(token.token_address)}
                                    </Link>
                                </td>
                                <td>{token.balance}</td>
                                {/*<td>{token.price !== "N/A" ? token.price * token.balance.toFixed(2) : "N/A" }</td>*/}
                                <td>{token.is_whitelisted === true ? <div className="badge badge-xs badge-success"></div> : <div className="badge badge-xs badge-error"></div>}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Portfolio;