/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Link } from 'react-router-dom';
import CopyButton from '../CopyButton';
import { BsDashLg } from 'react-icons/bs';

const PoolsDetailTable = ({ data }) => {
    function truncateHash(hash, length = 5) {
        const front = hash.substring(0, length);
        const back = hash.substring(hash.length - length);
        return `${front}...${back}`;
    }

    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-sm table-zebra w-full font-primary">
                <thead>
                    <tr>
                        <th>Contract Address</th>
                        <th>Tokens Balance</th>
                        <th>Protocol</th>
                    </tr>
                </thead>
                <tbody>
                    {data.result.pools.filter(pool => pool.underlying).map((pool) => (
                        <React.Fragment key={pool.address}>
                            <tr>
                                <th><Link to={`/address/${data.result.chainId}/${pool.address}`} className="link link-info link-hover">{truncateHash(pool.address)}</Link>&nbsp;<CopyButton text={pool.address} /> </th>
                                <td>
                                    {pool.underlying.map((token, index) => (
                                        <p key={token.token.address}>{token.token.symbol}: {Number(token.token.balance) < 0 ? 0 : Number(token.token.balance).toLocaleString()}</p>
                                    ))}
                                </td>
                                <td><img src={`/pool-logos/${pool.logoURI}`} className='w-6 h-6' />{pool.symbol || <BsDashLg />}</td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PoolsDetailTable;
