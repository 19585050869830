import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

import HomePage from './pages/HomePage';
import Block from './pages/Block';
import Transaction from './pages/Transaction';
import TransactionChainId from './pages/TransactionChainId';
import Address from './pages/Address';
import BlockDetails from './pages/BlockDetails';
import AddressDetails from './pages/AddressDetails';
import ChainInfo from './pages/ChainInfo';
import Ens from './pages/Ens';
import Token from './pages/Token';
import TokenDetail from './pages/TokenDetail';
import NotFoundPage from './pages/NotFoundPage';
import PoolsDetail from './pages/PoolsDetail';
import VerifyContract from './pages/VerifyContract';
import VerifyProxy from './pages/VerifyProxy';

import Layout from './components/Layout';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/chain/:chainId" element={<ChainInfo />} />
          <Route path="/block/:blockNum" element={<Block />} />
          <Route path="/block/:chainId/:blockNum" element={<BlockDetails />} />
          <Route path="/tx/:txid" element={<Transaction />} />
          <Route path="/tx/:chainId/:txid" element={<TransactionChainId />} />
          <Route path="/address/:address" element={<Address />} />
          <Route path="/address/:chainId/:address" element={<AddressDetails />} />
          <Route path="/ens/:ensName" element={<Ens />} />
          <Route path="/token/:chainId/:symbol" element={<Token />} />
          <Route path="/token/:chainId/:symbol/:address" element={<TokenDetail />} />
          <Route path="/pools/:chainId/:symbol/:address" element={<PoolsDetail />} />
          <Route path="/verify/:chainId/:address" element={<VerifyContract />} />
          <Route path="/verify-proxy/:chainId/:address" element={<VerifyProxy />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
