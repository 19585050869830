import React from 'react';
import { Link } from 'react-router-dom';

const AddressTxs = ({ mostRecentTxs, address, resetPage }) => {
    const truncateTxHash = (txHash) => {
        return txHash.slice(0, 10) + "...";
    }

    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-pin-cols table-zebra table-sm w-full font-primary">
                <thead>
                    <tr>
                        <th>Block</th>
                        <td>Txn Hash</td>
                        <td>Age</td>
                        <td>From</td>
                        <td>To</td>
                        <td>Value</td>
                    </tr>
                </thead>
                <tbody>
                    {mostRecentTxs && mostRecentTxs.length > 0 ?
                        mostRecentTxs.map((tx) => (
                            <tr key={tx.transaction_hash}>
                                <th>
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/block/${tx.chain_id}/${tx.block_number}`}
                                    >
                                        {tx.block_number}
                                    </Link>
                                </th>
                                <td>
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/tx/${tx.chain_id}/${tx.transaction_hash}`}
                                    >
                                        {truncateTxHash(tx.transaction_hash)}
                                    </Link>
                                </td>
                                <td>{tx.timestamp}</td>
                                <td>
                                    {address.toLowerCase() !== tx.from_address.toLowerCase() ?
                                        <Link
                                            className="link link-info link-hover"
                                            to={`/address/${tx.chain_id}/${tx.from_address}`}
                                            onClick={resetPage}
                                        >
                                            {tx.from_address}
                                        </Link> :
                                        tx.from_address
                                    }
                                </td>
                                <td>
                                    {tx.to_address === "0x" ?
                                        "Contract Creation" :
                                        (address.toLowerCase() !== tx.to_address.toLowerCase() ?
                                            <Link
                                                className="link link-info link-hover"
                                                to={`/address/${tx.chain_id}/${tx.to_address}`}
                                                onClick={resetPage}
                                            >
                                                {tx.to_address}
                                            </Link> :
                                            tx.to_address
                                        )
                                    }
                                </td>
                                <td>
                                    {address.toLowerCase() === tx.from_address.toLowerCase()
                                        ? -Math.abs(tx.value.toFixed(18))
                                        : address.toLowerCase() === tx.to_address.toLowerCase()
                                            ? Math.abs(tx.value.toFixed(18))
                                            : tx.value.toFixed(18)
                                    } {mostRecentTxs[0].ticker}
                                </td>
                            </tr>
                        )) :
                        <tr>
                            <td colSpan="5" className="text-center">
                                No Transactions Yet
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};
export default AddressTxs;
