import React from 'react';
import { CSVLink } from "react-csv";

const CSVExportButton = ({ data, filename }) => {
    const headers = Object.keys(data[0]).map(key => ({ label: key, key: key }));

    return (
        <CSVLink data={data} headers={headers} filename={filename}>
            <button className="btn btn-xs btn-primary btn-outline">Export CSV</button>
        </CSVLink>
    );
}

export default CSVExportButton;