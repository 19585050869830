import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { PropagateLoader } from 'react-spinners';
import HomeTable from '../components/tables/HomeTable';

function HomePage() {
    const [networks, setNetworks] = useState([]);
    const [loading, setLoading] = useState(true);
    const preloadImages = (networks) => {
        networks.forEach(network => {
            const img = new Image();
            img.src = network.logoURI;
        });
    };
    useEffect(() => {
        const interval = setInterval(() => {
            loadData();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        try {
            const response = await axios.get('/api/networks');
            const sortedNetworks = [...response.data].sort((a, b) => {
                return a.chainName.localeCompare(b.chainName);
            });
            preloadImages(sortedNetworks);
            setNetworks(sortedNetworks);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            console.error(`Error loading data: ${error}`);
        }
    }

    useEffect(() => {
        document.title = "vfat scan";
    }, []);

    return (
        <div>
            {loading ? (
                <div className="grid h-screen place-items-center">
                    <PropagateLoader color="#f1fa89" />
                </div>
            ) : (
                <HomeTable networks={networks} />
            )}
        </div>
    );
}

export default HomePage;
