import React, { useEffect } from 'react';

function NotFoundPage() {

    useEffect(() => {
        document.title = "vfat scan - 404";
    }, []);
    return (
        <div className="flex flex-col items-center justify-center h-screen text-gray-400">
            <h1 className="text-4xl">404</h1>
            <h2 className="text-2xl my-4">Page not found</h2>
        </div>

    );
}

export default NotFoundPage;