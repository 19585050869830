import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CustomModal from './CustomModal';
import './SearchBar.css'

function SearchBar({ networks, isDarkTheme }) {
  const navigate = useNavigate();
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [options, setOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const newOptions = networks.map((network) => {
      return {
        label: network.chainName,
        value: network.chainId,
        logoURI: network.logoURI,
      };
    });
    setOptions(newOptions);
  }, [networks]);

  function redirectToTransaction(event) {
    event.preventDefault();
    if (searchInput.trim() !== '') {
      let chainPath = selectedNetwork === null ? '' : `/${selectedNetwork.value}`;
      if (searchInput.length === 42) {
        navigate(`/address${chainPath}/${searchInput}`);
      } else if (searchInput.length === 66) {
        navigate(`/tx/${searchInput}`);
      } else if (/^\d+$/.test(searchInput) && !searchInput.startsWith('0x')) {
        navigate(`/block${chainPath}/${searchInput}`);
      } else if (isAlphaNumeric(searchInput) && !isENSName(searchInput)) {
        if (selectedNetwork === null) {
          toggleModal();
        } else {
          navigate(`/token${chainPath}/${searchInput}`);
        }
      } else if (isENSName(searchInput)) {
        navigate(`/ens/${searchInput}`);
      }
    }
  }



  function toggleModal() {
    setIsModalOpen(prevState => !prevState);
  }

  function isInvalidInput(searchInput) {
    if (
      searchInput.length !== 42 &&
      searchInput.length !== 66 &&
      searchInput.startsWith('0x')
    ) {
      return true;
    }
    if (isNaN(searchInput) && !isENSName(searchInput) && !isAlphaNumeric(searchInput)) {
      return true;
    }
    return false;
  }

  function isENSName(searchInput) {
    return /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)+$/.test(searchInput);
  }

  function isAlphaNumeric(searchInput) {
    return /^[a-zA-Z0-9_$-]+$/.test(searchInput);
  }

  return (
    <div className="form-control w-full lg:w-2/3">
      <form onSubmit={redirectToTransaction}>
        <div className="join w-full">
          <div className="select-container join-item">
            <Select
              options={options}
              value={selectedNetwork}
              placeholder={`Search ${networks.length} chains`}
              isClearable
              maxMenuHeight={700}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              formatOptionLabel={({ label, logoURI }) => (
                <div className="flex items-center">
                  <img src={logoURI} alt={label} className="rounded mr-3 w-5 h-5" /> {label}
                </div>
              )}
              onChange={(selectedOption) => setSelectedNetwork(selectedOption)}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: 300,
                  height: 48,
                  backgroundColor: isDarkTheme ? '#282a36' : '',
                  border: state.isFocused ? 'none' : provided.border,
                  boxShadow: state.isFocused ? 'none' : provided.boxShadow,
                  "&:hover": {
                    border: state.isFocused ? 'none' : provided.border,
                  }

                }),
                input: (provided) => ({
                  ...provided,
                  color: isDarkTheme ? 'white' : 'black',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: isDarkTheme ? 'white' : 'black',
                  backgroundColor: isDarkTheme
                    ? '#282a36'
                    : (state.isSelected
                      ? 'transparent'
                      : (state.isFocused ? '#3d3f4930' : provided.backgroundColor)),
                  "&:hover": {
                    backgroundColor: isDarkTheme
                      ? '#282a36'
                      : (state.isSelected
                        ? 'transparent'
                        : (state.isFocused ? '#3d3f4930' : provided.backgroundColor)),
                  }
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkTheme ? '#282a36' : '#fff',
                }),
                menuList: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkTheme ? '#282a36' : '#fff',
                }),

                indicatorSeparator: () => ({
                  display: 'none',
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkTheme ? '#282a36' : '',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkTheme ? '#282a36' : '',
                }),
                selectContainer: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkTheme ? '#282a36' : '',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: isDarkTheme ? '#fff' : '',
                }),
              }}
            />
          </div>
          <input
            type="text"
            placeholder=" Search by Address / Transaction / Block / Token / ENS"
            className={`input input-bordered join-item w-full ${isInvalidInput(searchInput) ? 'text-error' : ''}`}
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit" className="btn btn-square btn-primary join-item rounded-r-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
      </form>
      <CustomModal
        modalId="my-modal-6"
        title="Warning"
        message="Please select a network before searching for a token."
        buttonText="OK"
        isOpen={isModalOpen}
        onClose={toggleModal}
      />
    </div>
  );
}

export default SearchBar;
