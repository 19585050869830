import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { PropagateLoader } from 'react-spinners';

const ContractWrite = ({ contractInfo, network, address }) => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [functions, setFunctions] = useState([]);
    const [inputs, setInputs] = useState({});
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [correctNetwork, setCorrectNetwork] = useState(() => JSON.parse(localStorage.getItem('correctNetwork') || 'true'));

    useEffect(() => {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        const init = async () => {
            if (window.ethereum) {
                const currentNetworkId = await web3Instance.eth.net.getId();
                const targetNetworkId = parseInt(network, 10);
                const isCorrect = currentNetworkId === targetNetworkId;
                setCorrectNetwork(isCorrect);
                localStorage.setItem('correctNetwork', JSON.stringify(isCorrect));
            }
        };

        init();

        const checkNetwork = async () => {
            await init();
        };

        window.ethereum.on('networkChanged', checkNetwork);

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                checkNetwork();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.ethereum.removeListener('networkChanged', checkNetwork);
        };
    }, [network]);

    const switchNetwork = async (targetNetworkId) => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${targetNetworkId.toString(16)}` }],
            });
            setCorrectNetwork(true);
        } catch (switchError) {
            console.error('Error switching network', switchError);
        }
    };

    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            window.ethereum.request({ method: 'eth_accounts' })
                .then(accounts => {
                    if (accounts.length > 0) {
                        setAccount(accounts[0]);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching accounts", error);
                    setLoading(false);
                });
        }
        if (!window.ethereum) {
            setLoading(false);
        }
        if (contractInfo) {
            const abi = contractInfo.contract_abi || contractInfo;
            if (abi) {
                const abiFunctions = abi.filter(item => item.type === 'function');
                setFunctions(abiFunctions);
            }
        }


    }, [contractInfo]);

    const connectWallet = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts[0]);
        } catch (error) {
            console.error("Error connecting to MetaMask", error);
        }
    };

    const handleInputChange = (funcName, paramName, value) => {
        const inputKey = `${funcName}_${paramName}`;
        setInputs(prevInputs => ({
            ...prevInputs,
            [inputKey]: value
        }));
    };

    const formatResult = (outputType, result) => {
        if (result === undefined || result === null) {
            return '';
        }
        if (outputType.startsWith('uint')) {
            return new BigNumber(result).toFixed();
        }
        return result;
    };


    const callFunction = (func) => {
        const abi = contractInfo.contract_abi || contractInfo;
        const contract = new web3.eth.Contract(abi, address);

        const funcParams = func.inputs.map(input => inputs[`${func.name}_${input.name}`] || '');

        if (funcParams.includes('')) {
            console.error('Error: Not enough values provided for all inputs');
            return;
        }

        if (func.stateMutability === 'view') {
            contract.methods[func.name](...Object.values(funcParams)).call()
                .then(response => {
                    setResults(prevResults => ({ ...prevResults, [func.name]: response }));
                })
                .catch(error => console.error(error));
        } else {
            contract.methods[func.name](...Object.values(funcParams))
                .send({ from: account })
                .then(response => {
                    setResults(prevResults => ({ ...prevResults, [func.name]: response }));
                })
                .catch(error => console.error(error));
        }
    };

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    return (
        <div className='mb-7'>
            {!web3 && <p>Please install MetaMask to interact with the contract.</p>}
            {web3 && !account && (
                <div className="alert my-7">
                    <div className="badge badge-error badge-xs"></div>
                    <span>Connect wallet to interact </span>
                    <div>
                        <button onClick={connectWallet} className="btn btn-sm btn-primary btn-outline">Connect Wallet</button>
                    </div>
                </div>
            )}
            {web3 && account && !correctNetwork && (
                <div className="alert my-7">
                    <div className="badge badge-error badge-xs"></div>
                    <span>You're on the wrong network. Please switch to the correct network.</span>
                    <div>
                        <button onClick={() => switchNetwork(parseInt(network, 10))} className="btn btn-sm btn-primary btn-outline">Switch Network</button>
                    </div>
                </div>
            )}
            {web3 && account && (
                <div>
                    {functions.map(func => (
                        (func.constant === false || func.stateMutability !== 'view') && (
                            <div key={func.name} className="collapse collapse-arrow border border-base-200 bg-base-100 my-3">
                                <input type="checkbox" />
                                <div className="collapse-title text-md font-medium">
                                    {func.name}
                                </div>
                                <div className="collapse-content">
                                    {func.inputs.map((input, index) => (
                                        <div key={index} className="form-control mb-5">
                                            <input type="text" placeholder={`${input.type} - ${input.name}`} className="input input-bordered input-sm" onChange={(e) => handleInputChange(func.name, input.name, e.target.value)} />
                                        </div>
                                    ))}
                                    {func.outputs.map((output, index) => (
                                        <>
                                            <p key={index}>
                                                ({output.type}):&nbsp;
                                                <span>{formatResult(output.type, results[func.name])}</span>
                                            </p>
                                        </>
                                    ))}
                                    {func.payable && (
                                        <>
                                            <div className="form-control mb-5">
                                                <input type="text" placeholder="Amount (Ether)" className="input input-bordered input-sm" onChange={(e) => handleInputChange(func.name, "amount", e.target.value)} />
                                            </div>
                                        </>
                                    )}
                                    <button className="btn btn-primary btn-xs btn-outline float-left mt-5" onClick={() => callFunction(func)}>Call</button>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}

        </div>
    );
};

export default ContractWrite;
