import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import './VerifyProxy.css';
import { ToastContainer, Zoom, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function VerifyProxy() {
    const { address, chainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [proxy, setProxy] = useState(null);

    useEffect(() => {
        axios.get(`/api/findproxy/${chainId}/${address}`)
            .then((response) => {
                setProxy(response.data.result);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching and parsing data', error);
            });
    }, [address, chainId]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    const isAddressZero = proxy === '0x0000000000000000000000000000000000000000';

    const handleConfirmClick = () => {
        const postData = {
            proxyAddress: proxy,
            address: address,
            chainId: chainId
        };
        axios.post('/api/verify-proxy', postData)
            .then(response => {
                if (response.data.result) {
                    toast.success('Verification successful');
                    setTimeout(() => {
                        window.location.href = `/address/${chainId}/${address}`;
                    }, 5000);
                } else {
                    toast.error('Verification failed - ' + response.data.error);
                }
            })
            .catch(error => {
                toast.error('Error posting data');
                console.error('Error posting data', error);
            });
    };

    return (
        <div className='mt-10'>
            {isAddressZero ? (
                <>
                    <div className="popup-container">
                        <div className="popup alert-popup">
                            <div className="popup-icon alert-icon">
                                <svg
                                    className="alert-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <div className="alert-message">We are unable to determine proxied contract automatically. Please contact our support on Discord.</div>
                        </div>
                    </div>
                </>

            ) : (
                <div>
                    <ToastContainer theme='colored' transition={Zoom} position='bottom-right' />
                    <h1 className='font-bold mb-2'>Proxy contract found</h1>
                    <h1 className='font-bold mb-2'>{proxy}</h1>
                    <div>
                        <button className="btn btn-outline btn-primary" onClick={handleConfirmClick}>Confirm</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VerifyProxy;
