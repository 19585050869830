import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, Zoom, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function SolidityFlattened({ address, chainId, deployedBytecode }) {
    const [compilers, setCompilers] = useState([]);
    const [contractCode, setContractCode] = useState('');
    const [selectedCompiler, setSelectedCompiler] = useState('');
    const [selectedEvmVersion, setSelectedEvmVersion] = useState('');
    const [response, setResponse] = useState('');

    useEffect(() => {

        async function fetchCompilers() {
            try {
                const response = await axios.get('/api/compilers/vyper');

                setCompilers(response.data);

            } catch (error) {
                console.error('Error fetching compilers:', error);
            }
        }

        fetchCompilers();
    }, []);



    const handleVerifyAndPublish = async () => {

        try {
            // Prepare the verification data
            const verificationData = {
                bytecode: deployedBytecode,
                compilerVersion: selectedCompiler,
                contractCode: contractCode,
                address: address,
                chainId: chainId,

            };

            // Add evmVersion if it's selected
            if (selectedEvmVersion) {
                verificationData.evmVersion = selectedEvmVersion;
            }

            const response = await axios.post('/api/verify-contract/vyper/flattened', verificationData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setResponse(response.data);
            if (response.data.result === true) {
                toast.success("Contract successfully verified and published");
                setTimeout(() => {
                    window.location.href = `/address/${chainId}/${address}`;
                }, 5000);
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='mb-7'>
            <ToastContainer theme='colored' transition={Zoom} position='bottom-right' />
            <p className="text-md mt-7">Contract verification via Solidity (flattened source code)</p>
            <div className="form-control mt-7 max-w-xs" style={{ display: 'flex', alignItems: 'left', gap: '10px' }}>
                <label className="cursor-pointer">
                    <input type="checkbox" className="checkbox checkbox-primary checkbox-xs" style={{ marginRight: '5px' }} />
                    <span>Include nightly builds</span>
                </label>
            </div>
            <select
                className="select select-bordered w-full max-w-xs mt-7"
                value={selectedCompiler}
                onChange={(e) => setSelectedCompiler(e.target.value)}

            >
                <option value="" disabled selected>Compiler version</option>

                {compilers.map((compiler) => (
                    <option key={compiler} value={compiler}>{compiler}</option>
                ))}

            </select>
            <select
                className="select select-bordered w-full max-w-xs mt-7"
                value={selectedEvmVersion}
                onChange={(e) => setSelectedEvmVersion(e.target.value)}
            >
                <option value="" selected>EVM Version</option>
                <option value="homestead">Homestead</option>
                <option value="tangerineWhistle">Tangerine Whistle</option>
                <option value="spuriousDragon">Spurious Dragon</option>
                <option value="byzantium">Byzantium</option>
                <option value="constantinople">Constantinople</option>
                <option value="petersburg">Petersburg</option>
                <option value="istanbul">Istanbul</option>
                <option value="berlin">Berlin</option>
                <option value="london">London</option>
                <option value="paris">Paris</option>
                <option value="shanghai">Shanghai</option>
            </select>

            <div className="form-control my-5">
                <label className="label mb-3">
                    <span className="label-text font-medium">Contract Code</span>
                </label>
                <textarea className="textarea textarea-bordered h-72" value={contractCode}
                    onChange={(e) => setContractCode(e.target.value)}></textarea>
            </div>
            <button className="btn btn-primary my-7 btn-outline" onClick={handleVerifyAndPublish}>Verify and Publish</button>
        </div>
    );
}

export default SolidityFlattened;