import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import { Link } from 'react-router-dom';
import { BsSun, BsMoon, BsDiscord, BsLightbulb } from 'react-icons/bs';
import { RiTwitterXLine } from 'react-icons/ri';
import { AiOutlineGithub, AiOutlineHome, AiOutlineBug } from 'react-icons/ai';
import { SiFauna, SiEthereum } from 'react-icons/si';
import './Layout.css';
import ThemeContext from '../ThemeContext';

export default function Layout({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(window.innerWidth > 768);
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      setIsDarkTheme(theme === 'dracula');
      document.documentElement.setAttribute('data-theme', theme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkTheme ? 'dracula' : 'emerald';
    setIsDarkTheme(!isDarkTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  return (
    <div className="h-screen w-full flex flex-col font-primary">
      <NavBar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} isDarkTheme={isDarkTheme} />
      <div className="drawer lg:drawer-open">
        <input
          id="my-drawer-3"
          type="checkbox"
          checked={isDrawerOpen}
          onChange={() => setIsDrawerOpen(!isDrawerOpen)}
          className="drawer-toggle"
        />
        <div className="drawer-content flex flex-col pt-20 overflow-x-auto" id="scroll-container">
          <div className="container mx-auto">
            <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
              {children}
            </ThemeContext.Provider>
          </div>
        </div>
        <div className="drawer-side pt-20">
          <label htmlFor="my-drawer-3" className="drawer-overlay" />
          <ul className={`menu h-[calc(100vh-5rem)] w-64 overflow-y-auto ${isDarkTheme ? 'bg-base-100' : 'bg-base-200'} p-4`}>
            <li className="menu-item px-1 py-1 text-md"><Link to="/"><span><AiOutlineHome />&nbsp;Home</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://vfat.tools" target="_blank" rel="noopener noreferrer"><span><SiFauna />&nbsp;vfat.tools</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://vfat.io" target="_blank" rel="noopener noreferrer"><span><SiFauna />&nbsp;vfat.io</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://discord.gg/vfat" target="_blank" rel="noopener noreferrer"><span><BsDiscord />&nbsp;Discord</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://twitter.com/vfat_io" target="_blank" rel="noopener noreferrer"><span><RiTwitterXLine />&nbsp;Twitter</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://github.com/vfat-io" target="_blank" rel="noopener noreferrer"><span><AiOutlineGithub />&nbsp;GitHub</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://vfat.canny.io/idea-box" target="_blank" rel="noopener noreferrer"><span><BsLightbulb />&nbsp;Leave Feedback</span></Link></li>
            <li className="menu-item px-1 py-1 text-md"><Link to="https://vfat.canny.io/bugs" target="_blank" rel="noopener noreferrer"><span><AiOutlineBug />&nbsp;Report Bug</span></Link></li>
            <li className="menu-item px-1 py-1 text-md">
              <div onClick={toggleTheme}>
                {!isDarkTheme ? <BsSun /> : <BsMoon />
                }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
