import React from 'react';
import { Link } from 'react-router-dom';

const AddressNftTxs = ({ mostRecentNftTxs, address, resetPage }) => {
    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-pin-cols table-zebra table-sm w-full font-primary">
                <thead>
                    <tr>
                        <th>Block</th>
                        <td>Age</td>
                        <td>NFT Address</td>
                        <td>From</td>
                        <td>To</td>
                        <td>NFT ID</td>
                    </tr>
                </thead>
                <tbody>
                    {mostRecentNftTxs && mostRecentNftTxs.length > 0 ? (
                        mostRecentNftTxs.map((tx, index) => (
                            <tr key={`${tx.block_number}-${index}`}>
                                <th>
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/block/${tx.chain_id}/${tx.block_number}`}
                                    >
                                        {tx.block_number}
                                    </Link>
                                </th>
                                <td>{tx.timestamp}</td>
                                <td>
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/address/${tx.chain_id}/${tx.token_address}`}
                                        onClick={resetPage}
                                    >
                                        {tx.token_address}
                                    </Link>
                                </td>
                                <td>
                                    {address.toLowerCase() !== tx.from_address.toLowerCase() ? (
                                        <Link
                                            className="link link-info link-hover"
                                            to={`/address/${tx.chain_id}/${tx.from_address}`}
                                            onClick={resetPage}
                                        >
                                            {tx.from_address}
                                        </Link>
                                    ) : (
                                        tx.from_address
                                    )}
                                </td>
                                <td>
                                    {address.toLowerCase() !== tx.to_address.toLowerCase() ? (
                                        <Link
                                            className="link link-info link-hover"
                                            to={`/address/${tx.chain_id}/${tx.to_address}`}
                                            onClick={resetPage}
                                        >
                                            {tx.to_address}
                                        </Link>
                                    ) : (
                                        tx.to_address
                                    )}
                                </td>
                                <td>
                                    {tx.token_id}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">
                                No Transactions Yet
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AddressNftTxs;