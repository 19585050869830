/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import GlobalTxs from '../components/tables/GlobalTxs';

function Ens() {
    const { ensName } = useParams();
    const location = useLocation();
    const fragment = location.hash.slice(1);
    const [chains, setChains] = useState(null);
    const [networks, setNetworks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('overview');
    const [globalTxs, setGlobalTxs] = useState([]);
    const [transformedChains, setTransformedChains] = useState(null);

    useEffect(() => {
        const fetchNetworks = async () => {
            try {
                const response = await axios.get('/api/networks');
                setNetworks(response.data);
            } catch (error) {
                console.error('Failed to fetch networks', error);
            }
        };

        fetchNetworks();
    }, []);

    useEffect(() => {
        if (fragment === 'txs') {
            setActiveTab('transactions');
        } else if (fragment === 'overview') {
            setActiveTab('overview');
        }
    }, [fragment]);

    useEffect(() => {
        const fetchChains = async () => {
            try {
                const response = await axios.get(`/api/getensbyname/${ensName}`);
                setChains(response.data);
            } catch (error) {
                console.error('Failed to fetch chains', error);
            }
        };

        fetchChains();
    }, [ensName]);

    useEffect(() => {
        if (chains && networks) {
            const newTransformedChains = chains.result
                .map((chain) => {
                    const matchingNetwork = networks.find(
                        (network) => network.chainId === chain.chain_id
                    );

                    if (matchingNetwork) {
                        return {
                            ...chain,
                            chainName: matchingNetwork.chainName,
                            logoURI: matchingNetwork.logoURI,
                        };
                    }
                    return chain;
                })
                .sort((a, b) => a.chain_id - b.chain_id);

            setTransformedChains(newTransformedChains);
        }
    }, [chains, networks]);

    useEffect(() => {
        if (chains && chains.result && chains.result.length > 0 && chains.result[0].address) {
            const fetchGlobalTxs = async () => {
                try {
                    const globalTxsResponse = await axios.get(`/api/getrecenttxs/${chains.result[0].address}`);
                    setGlobalTxs(globalTxsResponse.data.result);
                    setLoading(false);
                } catch (error) {
                    console.error('Failed to fetch global txs', error);
                }
            };

            fetchGlobalTxs();
        }
    }, [chains, ensName]);



    useEffect(() => {
        document.title = "vfat scan - " + ensName;
    }, [ensName]);

    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    return (
        <>
            <div className='mt-3'>
                {loading ? (
                    <div className="grid h-screen place-items-center">
                        <PropagateLoader color="#f1fa89" />
                    </div>
                ) : (
                    <>
                        <div className="card w-full bg-base-100 shadow-xl mb-7">
                            <div className="card-body">
                                <div className="text-sm breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>ENS Name Found</li>
                                    </ul>
                                </div>
                                {chains && chains.result.length === 0 && <h4 className="card-title">No ENS Found</h4>}
                                {chains && chains.result.length === 1 && <h4 className="card-title">Found in {chains.result.length} Blockchain</h4>}
                                {chains && chains.result.length > 1 && <h4 className="card-title">Found in {chains.result.length} Blockchains</h4>}
                                <div className="break-words">
                                    {ensName}
                                </div>
                            </div>
                        </div>
                        <div role="tablist" className="tabs tabs-lifted my-5" style={{ display: 'inline-block' }} >
                            <a
                                role='tab'
                                className={`tab ${activeTab === 'overview' ? 'tab-active' : ''}`}
                                onClick={() => switchTab('overview', 'overview')}>
                                Overview
                            </a>
                            <a
                                role='tab'
                                className={`tab ${activeTab === 'transactions' ? 'tab-active' : ''}`}
                                onClick={() => switchTab('transactions', 'txs')}>
                                Global Transactions
                            </a>
                        </div>
                        {activeTab === "transactions" && transformedChains && transformedChains.length > 0 && transformedChains[0].address ?
                            <GlobalTxs mostRecentTxs={globalTxs} address={transformedChains[0].address} />
                            :
                            <div className="flex flex-wrap -m-2">
                                {transformedChains && transformedChains.map((chain, index) =>
                                    chain.chainName !== undefined && (
                                        <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                                            <div className="card bg-base-100 text-base-content border border-primary">
                                                <div className="card-body">
                                                    <div className="flex items-center">
                                                        <img src={chain.logoURI} alt={chain.chainName} className="w-5 rounded mr-2" />
                                                        <p className="mb-0">{chain.chainName}
                                                            {chain.name &&
                                                                <div className="badge badge-success badge-outline badge-md ml-5">ENS REGISTERED</div>}</p>
                                                    </div>
                                                    <Link to={`/address/${chain.chain_id}/${chain.address}`} className="link link-info link-hover break-words">{chain.address}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        }

                    </>
                )}
            </div>
        </>
    );
}
export default Ens;    