import React from 'react';
import SearchBar from './SearchBar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';

function NavBar({ isDrawerOpen, setIsDrawerOpen, isDarkTheme }) {
    const [networks, setNetworks] = useState([]);

    useEffect(() => {
        async function fetchNetworks() {
            try {
                const response = await axios.get('/api/networks');
                const sortedNetworks = [...response.data].sort((a, b) => {
                    return a.chainName.localeCompare(b.chainName);
                });
                setNetworks(sortedNetworks);
            } catch (error) {
                console.error('Error fetching networks:', error);
            }
        }

        fetchNetworks();
    }, []);
    return (
        <div className="navbar fixed top-0 z-50 flex h-20 w-full border-b border-slate-600 bg-base-200 bg-opacity-30 backdrop-blur-lg backdrop-filter">
            <div className="flex-none lg:hidden">
                <label
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    className={`btn btn-sm btn-circle swap swap-rotate ${isDrawerOpen ? 'swap-active' : ''}`}
                >
                    <svg
                        className="swap-off fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                    ><path
                            d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"
                        /></svg>
                    <svg
                        className="swap-on fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                    ><polygon
                            points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"
                        /></svg>
                </label>
            </div>
            <span>
                <Link to="/">
                    <img
                        src={!isDarkTheme ? "/vfat-image-dark.png" : "/vfat-image.png"}
                        width="64"
                        height="48"
                        alt=""
                        className="logo"
                    />
                </Link>
            </span>
            <div className="flex-grow justify-center justify-items-center">
                <SearchBar networks={networks} isDarkTheme={isDarkTheme} />
            </div>
        </div>
    );
}

export default NavBar;
