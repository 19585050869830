import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, Zoom, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MdClear } from "react-icons/md";


function SolidityStandardJSON({ isDarkTheme, address, chainId, deployedBytecode }) {

    const [compilers, setCompilers] = useState([]);
    const [file, setFile] = useState(null);
    const [selectedCompiler, setSelectedCompiler] = useState('');
    const [response, setResponse] = useState('');
    const clearFile = () => {
        setFile(null);
        document.getElementById('file').value = '';
    };

    useEffect(() => {

        async function fetchCompilers() {
            try {
                const response = await axios.get('/api/compilers/solidity');
                setCompilers(response.data);

            } catch (error) {
                console.error('Error fetching compilers:', error);
            }
        }

        fetchCompilers();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('chain_id', chainId);
        formData.append('address', address);
        formData.append('file', document.getElementById('file').files[0]);
        formData.append('bytecode', deployedBytecode);
        formData.append('compilerVersion', selectedCompiler);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setResponse(response.data);
            if (response.data.result === true) {
                toast.success("Contract successfully verified and published");
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error("Error uploading file");
        }
    };


    return (
        <div className='mt-7'>
            <ToastContainer theme='colored' transition={Zoom} position='bottom-right' />
            <p className="text-md mt-7">Contract verification via Solidity (standard JSON input)</p>
            <div className="form-control mt-7" style={{ display: 'flex', alignItems: 'left', gap: '10px' }}>
                <label className="cursor-pointer">
                    <input type="checkbox" className="checkbox checkbox-primary checkbox-xs" style={{ marginRight: '5px' }} />
                    <span>Include nightly builds</span>
                </label>
            </div>
            <select
                className="select select-bordered w-full max-w-xs mt-7"
                onChange={(e) => setSelectedCompiler(e.target.value)}
                value={selectedCompiler}

            >
                <option value="" disabled selected>Compiler version</option>
                {compilers.map((compiler) => (
                    <option key={compiler} value={compiler}>{compiler}</option>
                ))}
            </select>
            <div>
                <form className="file-upload-form mt-10">
                    <label htmlFor="file" className="file-upload-label" style={{ backgroundColor: isDarkTheme ? '' : '#e8e8e8', color: isDarkTheme ? '#e5e5e5' : '' }}>
                        {file ? (
                            <div className="file-selected flex items-center justify-between">
                                <span className="truncate">{file.name}</span>
                                <button type="button" className="file-clear-button ml-2" onClick={clearFile}>
                                    <MdClear className='w-7 h-7 pt-5' style={{ fill: isDarkTheme ? '' : '#66cc8a' }} />
                                </button>
                            </div>

                        ) : (
                            <div className="file-upload-design">
                                <div className="file-upload-design">
                                    <svg viewBox="0 0 640 512" height="1em" style={{ fill: isDarkTheme ? '' : '#66cc8a' }}>
                                        <path
                                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                                        ></path>
                                    </svg>
                                    <p>Drag and Drop</p>
                                    <p>or</p>
                                    <span className="browse-button">Browse file</span>
                                </div>
                            </div>
                        )}
                        <input id="file" type="file" onChange={handleFileChange} />
                    </label>
                </form>
            </div>
            <button className="btn btn-primary my-10 btn-outline" onClick={handleSubmit}>Verify and Publish</button>
        </div>
    );
}

export default SolidityStandardJSON;