import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

const PoolsTable = ({ data }) => {
    const aggregatedData = useMemo(() => {
        const poolCounts = {};

        data.result.pools.forEach(pool => {
            if (pool.underlying) {
                const sortedUnderlying = pool.underlying.sort((a, b) => a.token.symbol.localeCompare(b.token.symbol));
                const pair = sortedUnderlying.map(token => token.token.symbol).join("_");
                const logos = sortedUnderlying.map(token => token.token.logoURI);

                if (poolCounts[pair]) {
                    poolCounts[pair].count++;
                } else {
                    poolCounts[pair] = { count: 1, logos };
                }
            }
        });

        return poolCounts;
    }, [data]);



    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-sm table-zebra w-full">
                <thead>
                    <tr>
                        <th>Pair</th>
                        <th>Pools Count</th>
                        <th>Pools Overview</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(aggregatedData).map(([pair, { count, logos }]) => (
                        <React.Fragment key={pair}>
                            <tr>
                                <th>
                                    <div className="flex items-center">
                                        {logos.map((logo, i) => (
                                            <img key={i} src={logo} alt="Logo" className="w-4 h-4 mr-2" />
                                        ))}
                                        {pair}
                                    </div>
                                </th>
                                <td>{count}</td>
                                <td><Link to={`/pools/${data.result.chainId}/${pair}/${data.result.address}`} className="btn btn-primary btn-sm">Pools</Link></td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PoolsTable;
