import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

function Block() {
    const { blockNum } = useParams();
    const [chains, setChains] = useState(null);
    const [networks, setNetworks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [transformedChains, setTransformedChains] = useState(null);
    useEffect(() => {
        const fetchNetworks = async () => {
            try {
                const response = await axios.get('/api/networks');
                setNetworks(response.data);
            } catch (error) {
                console.error('Failed to fetch networks', error);
            }
        };

        fetchNetworks();
    }, []);

    useEffect(() => {
        const fetchChains = async () => {
            try {
                const response = await axios.get(`/api/block/${blockNum}`);
                setChains(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch chains', error);
            }
        };

        fetchChains();
    }, [blockNum]);

    useEffect(() => {
        if (chains && networks) {
            const newTransformedChains = chains.result
                .map((chain) => {
                    const matchingNetwork = networks.find(
                        (network) => network.chainId === chain.chain_id
                    );

                    if (matchingNetwork) {
                        return {
                            ...chain,
                            chainName: matchingNetwork.chainName,
                            logoURI: matchingNetwork.logoURI,
                        };
                    }
                    return chain;
                })
                .sort((a, b) => a.chain_id - b.chain_id);

            setTransformedChains(newTransformedChains);
        }
    }, [chains, networks]);

    useEffect(() => {
        document.title = "vfat scan - " + blockNum;
    }, [blockNum]);

    return (
        <>
            <div className='mt-3'>
                {loading ? (
                    <div className="grid h-screen place-items-center">
                        <PropagateLoader color="#f1fa89" />
                    </div>
                ) : (
                    <>
                        <div className="card w-full bg-base-100 shadow-xl mb-7">
                            <div className="card-body">
                                <div className="text-sm breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Blocks Found</li>
                                    </ul>
                                </div>
                                {chains && chains.result.length === 0 && <h4 className="card-title">No Blocks Found</h4>}
                                {chains && chains.result.length === 1 && <h4 className="card-title">Found in {chains.result.length} Blockchain</h4>}
                                {chains && chains.result.length > 1 && <h4 className="card-title">Found in {chains.result.length} Blockchains</h4>}
                                <div className="break-words">
                                    {blockNum}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap -m-2">
                            {transformedChains && transformedChains.map((chain, index) =>
                                chain.chainName !== undefined && (
                                    <div key={index} className="w-full md:w-1/2 lg:w-1/4 p-2">
                                        <div className="card bg-base-100 text-base-content border border-primary">
                                            <div className="card-body">
                                                <div className="flex items-center">
                                                    <img src={chain.logoURI} alt={chain.chainName} className="w-5 rounded mr-2" />
                                                    <p className="mb-0">{chain.chainName}</p>
                                                </div>
                                                <Link to={`/block/${chain.chain_id}/${blockNum}`} className="link link-info link-hover">{blockNum}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default Block;    