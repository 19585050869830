/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import HoldersTable from '../components/tables/HoldersTable';
import PoolsTable from '../components/tables/PoolsTable';
import CopyButton from '../components/CopyButton';
import { TbCircleLetterT, TbCircleLetterC, TbCircleLetterI } from 'react-icons/tb';
import { BiDollarCircle } from 'react-icons/bi';

function TokenDetail() {
    function truncateHash(hash, length = 10) {
        const front = hash.substring(0, length);
        const back = hash.substring(hash.length - length);
        return `${front}...${back}`;
    }
    const { symbol } = useParams();
    const { chainId } = useParams();
    const { address } = useParams();
    const [loading, setLoading] = useState(true);
    const [tokens, setTokens] = useState(null);
    const [liquidity, setLiquidity] = useState(null);
    const [activeTab, setActiveTab] = useState("holders");
    const [holdersData, setHoldersData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();
    const fragment = location.hash.slice(1);

    useEffect(() => {
        if (fragment === 'holders') {
            setActiveTab('holders');
        } else if (fragment === 'pools') {
            setActiveTab('pools');
        }
    }, [fragment]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchTokens = async () => {
            const response = await axios.get(`/api/gettokenbyaddress/${chainId}/${address}`);
            setTokens(response.data);
        };

        const fetchLiquidity = async () => {
            const response = await axios.get(`/api/getliquidity/${chainId}/${address}`);
            setLiquidity(response.data);
        };

        const fetchHolders = async () => {
            const response = await axios.get(`/api/richlist/${chainId}/${address}`);
            setHoldersData(response.data);
        };

        Promise.all([fetchTokens(), fetchLiquidity(), fetchHolders()])
            .then(() => setLoading(false))
            .catch((error) => console.error('Failed to fetch data', error));
    }, [chainId, address]);

    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    useEffect(() => {
        document.title = "vfat scan - " + symbol;
    }, [symbol]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    if (!loading && tokens) {
        return (
            <>
                <div className="card w-full bg-base-100 shadow-xl mb-7">
                    <div className="card-body">
                        <div className="text-sm breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Token Detail</li>
                            </ul>
                        </div>
                        {tokens && tokens.result.length > 0 &&
                            <>
                                <h4 className="card-title">{tokens.result[0].logo == null ? <TbCircleLetterT className="w-6 h-6 mr-2 inline-block rounded-full" /> : <img src={tokens.result[0].logo} alt={tokens.result[0].name} className="w-6 h-6 mr-2 inline-block rounded-full" />}{tokens.result[0].name}&nbsp;-&nbsp;{tokens.result[0].symbol_uppercased}</h4>
                                <p className="card-description"> <div className="tooltip" data-tip="USD Price"> <BiDollarCircle className="w-6 h-6 mr-2 inline-block rounded-full" /></div>{liquidity.result.price || "N/A"}</p>
                                <p className="card-description">
                                    <div className="tooltip" data-tip="Contract">
                                        <TbCircleLetterC className="w-6 h-6 mr-2 inline-block rounded-full" />
                                    </div>
                                    <Link to={`/address/${chainId}/${tokens.result[0].token_address}`}>
                                        {isMobile ? truncateHash(tokens.result[0].token_address) : tokens.result[0].token_address}
                                    </Link>
                                    &nbsp;
                                    <CopyButton text={tokens.result[0].token_address} />
                                </p>
                                <p className="card-description"> <TbCircleLetterI className="w-6 h-6 mr-2 inline-block rounded-full" />Decimals:&nbsp;{tokens.result[0].decimals}</p>
                            </>}
                    </div>
                </div>
                <div role="tablist" className="tabs tabs-lifted mt-5" style={{ display: 'inline-block' }} >
                    <a
                        role='tab'
                        className={`tab ${activeTab === "holders" ? "tab-active" : ""}`}
                        onClick={() => switchTab("holders", "holders")}
                    >
                        Holders
                    </a>
                    <a
                        role='tab'
                        className={`tab ${activeTab === "pools" ? "tab-active" : ""}`}
                        onClick={() => switchTab("pools", "pools")}
                    >
                        Pools
                    </a>
                </div>
                {activeTab === 'holders' &&
                    <>
                        {holdersData && holdersData.result.length > 0 &&
                            <HoldersTable data={holdersData} symbol={symbol} />
                        }
                    </>}
                {activeTab === 'pools' &&
                    <>
                        {liquidity && liquidity.result.pools.length > 0 &&
                            <PoolsTable data={liquidity} />
                        }
                        {liquidity && liquidity.result.pools.length === 0 &&
                            <p className="text-center">No pools found.</p>}

                    </>}
            </>
        );
    }
}

export default TokenDetail;