/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import CopyButton from '../components/CopyButton';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import LogsList from '../components/LogsList';


export default function TransactionChainId() {
    const { chainId, txid } = useParams();
    const [activeTab, setActiveTab] = useState('overview');
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [signature, setSignature] = useState(null);
    const location = useLocation();
    const fragment = location.hash.slice(1);

    useEffect(() => {
        if (fragment === 'overview') {
            setActiveTab('overview');
        } else if (fragment === 'logs') {
            setActiveTab('logs');
        }
    }, [fragment]);

    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    useEffect(() => {
        const fetchTransaction = async () => {
            try {
                const txResponse = await axios.get(`/api/tx/${chainId}/${txid}`);
                const networksResponse = await axios.get('/api/networks');

                if (txResponse.data.result && Object.keys(txResponse.data.result).length !== 0) {
                    let newTx = { ...txResponse.data.result };
                    const { data: networksData } = networksResponse;
                    networksData.forEach((item) => {
                        if (item.chainId === newTx.chain_id) {
                            newTx.chainName = item.chainName;
                            newTx.ticker = item.nativeCurrency.symbol;
                            newTx.logoURI = item.logoURI;
                        }
                    });
                    setTransaction(newTx);
                }
            } catch (error) {
                console.error('Error fetching transaction:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchTransaction();
    }, [chainId, txid]);

    useEffect(() => {
        const fetchSignature = async () => {
            try {
                if (transaction && transaction.selector) {
                    const signatureResponse = await axios.get(`/api/getsig/${transaction.selector}`);
                    if (signatureResponse.data.result === true) {
                        setSignature(signatureResponse.data);
                    }
                }
            } catch (error) {
                console.error('Error fetching signature:', error);
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                } else if (error.request) {
                    console.error('Request made, no response:', error.request);
                } else {
                    console.error('Error message:', error.message);
                }
            }
        };
        if (transaction && transaction.selector) {
            fetchSignature();
        }
    }, [transaction?.selector]);


    useEffect(() => {
        document.title = "vfat scan - " + txid;
    }, [txid]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    if (!transaction) {
        return <p className="text-center mt-5">The transaction cannot be found</p>;
    }

    return (
        <>
            {
                transaction ? (
                    <>
                        <div className="card w-full bg-base-100 shadow-xl">
                            <div className="card-body">
                                <div className="text-sm breadcrumbs">
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>Transaction</li>
                                    </ul>
                                </div>
                                <h4 className="card-title">Transactions Details</h4>
                                <div className="break-words">
                                    {transaction.transaction_hash}&nbsp;
                                    <CopyButton text={transaction.transaction_hash} />
                                </div>
                            </div>
                        </div>
                        <div role="tablist" className="tabs tabs-lifted mt-5" style={{ display: 'inline-block' }} >
                            <a role="tab" className={`tab tab-lifted ${activeTab === "overview" ? "tab-active" : ""}`} onClick={() => switchTab("overview", "overview")}>
                                Overview
                            </a>
                            <a
                                role="tab"
                                className={`indicator tab tab-lifted ${activeTab === "logs" ? "tab-active" : ""} ${transaction.logs && transaction.logs.length > 0 ? "" : "tab-disabled"}`}
                                onClick={() => { if (transaction.logs && transaction.logs.length > 0) switchTab("logs", "logs"); }}
                            >
                                Logs
                            </a>
                        </div>
                        {activeTab === 'overview' && (
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Chain ID:</strong>
                                </div>
                                <div className="w-full md:w-4/5 p-3">
                                    <Link to={`/chain/${transaction.chain_id}`} className="link link-info link-hover">{transaction.chain_id}</Link>
                                </div>

                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Block Number:</strong>
                                </div>
                                <div className="w-full md:w-4/5 p-3">
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/block/${transaction.chain_id}/${transaction.block_number}`}
                                    >
                                        {transaction.block_number}
                                    </Link>&nbsp;
                                    <CopyButton text={transaction.block_number} />
                                </div>

                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Value:</strong>
                                </div>
                                <div className="w-full md:w-4/5 p-3">
                                    {transaction.value} {transaction.ticker}
                                </div>

                                <div className="w-full md:w-1/5 p-3">
                                    <strong>From:</strong>
                                </div>
                                <div className="w-full flex md:w-4/5 p-3">
                                    <div className="break-words" style={{ maxWidth: '90%' }}>
                                        <Link
                                            className="link link-info link-hover"
                                            to={`/address/${transaction.chain_id}/${transaction.from_address}`}
                                        >

                                            {transaction.from_address}
                                        </Link>&nbsp;
                                        <CopyButton text={transaction.from_address} />
                                    </div>
                                </div>

                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Interacted With (To):</strong>
                                </div>
                                <div className="w-full flex md:w-4/5 p-3">
                                    <div className="break-words" style={{ maxWidth: '90%' }}>
                                        {transaction.to_address === '0x' ? (
                                            <span>Contract Creation</span>
                                        ) : (
                                            <>
                                                <Link
                                                    className="link link-info link-hover"
                                                    to={`/address/${transaction.chain_id}/${transaction.to_address}`}
                                                >
                                                    {transaction.to_address}
                                                </Link>&nbsp;
                                                <CopyButton text={transaction.to_address} />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Gas Price:</strong>
                                </div>
                                <div className="w-full md:w-4/5 p-3">
                                    {transaction.gas_price}
                                </div>
                                {transaction.selector && (
                                    <>
                                        <div className="w-full md:w-1/5 p-3">
                                            <strong>Selector:</strong>
                                        </div>
                                        <div className="w-full md:w-4/5 p-3">
                                            {transaction.selector}
                                            {signature && signature.result === true && (
                                                <>
                                                    <div className="break-words" style={{ maxWidth: '90%' }}>
                                                        <strong>{signature.text_sig}</strong>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                                <div className="w-full md:w-1/5 p-3">
                                    <strong>Input Data:</strong>
                                </div>
                                <div className="w-full md:w-4/5 p-3">
                                    <div className="break-words" style={{ maxWidth: '90%' }}>
                                        {transaction.tx_data}
                                    </div>
                                </div>
                            </div>

                        )}
                        {activeTab === 'logs' && transaction.logs && transaction.logs.length > 0 && (
                            <LogsList logs={transaction.logs} chainId={transaction.chain_id} />
                        )}

                    </>
                ) : (
                    <p className="text-center mt-5">The transaction cannot be found</p>
                )
            }
        </>
    );
}