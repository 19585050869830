/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import GlobalTxs from '../components/tables/GlobalTxs';

const Address = () => {
    const [mergedChains, setMergedChains] = useState([]);
    const { address } = useParams();
    const location = useLocation();
    const fragment = location.hash.slice(1);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('overview');
    const [globalTxs, setGlobalTxs] = useState([]);

    useEffect(() => {
        if (fragment === 'txs') {
            setActiveTab('transactions');
        } else if (fragment === 'overview') {
            setActiveTab('overview');
        }
    }, [fragment]);

    useEffect(() => {
        const fetchNetworksAndChains = async () => {
            const networksResponse = await axios.get('/api/networks');
            const mergedChainsResponse = await axios.get(`/api/address/${address}`);
            const globalTxsResponse = await axios.get(`/api/getrecenttxs/${address}`);
            const updatedChains = mergedChainsResponse.data.result.map((chain) => {
                const matchingNetwork = networksResponse.data.find((network) => network.chainId === chain.chain_id);
                if (matchingNetwork) {
                    return {
                        ...chain,
                        chainName: matchingNetwork.chainName,
                        logoURI: matchingNetwork.logoURI,
                    };
                }
                return chain;
            }).sort((a, b) => a.chain_id - b.chain_id);

            setMergedChains(updatedChains);
            setGlobalTxs(globalTxsResponse.data.resullt);
            setLoading(false);
        };
        fetchNetworksAndChains();
    }, [address]);

    useEffect(() => {
        document.title = "vfat scan - " + address;
    }, [address]);

    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    return (
        <>
            {loading && (
                <div className="grid h-screen place-items-center">
                    <PropagateLoader color="#f1fa89" />
                </div>
            )}
            {!loading && (
                <>
                    <div className="card w-full bg-base-100 shadow-xl mb-7">
                        <div className="card-body">
                            <div className="text-sm breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Addresses Found</li>
                                </ul>
                            </div>
                            {mergedChains.length === 0 && <h4 className="card-title">No Addresses Found</h4>}
                            {mergedChains.length === 1 && <h4 className="card-title">Found in 1 Blockchain</h4>}
                            {mergedChains.length > 1 && <h4 className="card-title">Found in {mergedChains.length} Blockchains</h4>}
                            <div className="break-words">{address}</div>
                        </div>
                    </div>
                    <div role="tablist" className="tabs tabs-lifted my-5" style={{ display: 'inline-block' }} >
                        <a
                            role='tab'
                            className={`tab ${activeTab === 'overview' ? 'tab-active' : ''}`}
                            onClick={() => switchTab('overview', 'overview')}>
                            Overview
                        </a>
                        <a
                            role='tab'
                            className={`tab ${activeTab === 'transactions' ? 'tab-active' : ''}`}
                            onClick={() => switchTab('transactions', 'txs')}>
                            Global Transactions
                        </a>
                    </div>
                    {activeTab === "transactions" ?
                        <GlobalTxs mostRecentTxs={globalTxs} address={address} />
                        :
                        <div className="flex flex-wrap -m-2">
                            {mergedChains.map(chain =>
                                chain.chainName !== undefined && (
                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2" key={chain.chain_id}>
                                        <div className="card bg-base-100 text-base-content border border-primary">
                                            <div className="card-body">
                                                <div className="flex items-center">
                                                    <img src={chain.logoURI} alt={chain.chainName} className="w-5 rounded mr-2" />
                                                    <p className="mb-0">{chain.chainName}</p>
                                                </div>
                                                <Link to={`/address/${chain.chain_id}/${address}`} className="link link-info link-hover break-words">{address}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    }
                </>
            )
            }
        </>
    );
}

export default Address;
