import React from 'react';
import { Link } from 'react-router-dom';
import CopyButton from '../CopyButton';

const HoldersTable = ({ data, symbol }) => {
    function truncateHash(hash, length = 5) {
        const front = hash.substring(0, length);
        const back = hash.substring(hash.length - length);
        return `${front}...${back}`;
    }

    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-sm table-zebra w-full font-primary">
                <thead>
                    <tr>
                        <th>Holder</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {data.result.map((holder) => (
                        <React.Fragment key={holder.holder_address}>
                            <tr>
                                <th>
                                    <Link to={`/address/${holder.chain_id}/${holder.holder_address}`} className="link link-info link-hover">
                                        {truncateHash(holder.holder_address)}
                                        &nbsp;
                                    </Link>
                                    <CopyButton text={holder.holder_address} />
                                </th>
                                <td>{holder.balance.toLocaleString()}&nbsp;{symbol}</td>
                            </tr >
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HoldersTable;
