/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import CopyButton from '../components/CopyButton';
import { PropagateLoader } from 'react-spinners';
import AddressTxs from '../components/tables/AddressTxs';
import AddressTokenTxs from '../components/tables/AddressTokenTxs';
import AddressNftTxs from '../components/tables/AddressNftTxs';
import AddressErc1155Txs from '../components/tables/AddressErc1155Txs';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import CSVExportButton from '../components/CSVExportButton';
import Portfolio from '../components/tables/Portfolio';
import AceEditor from "react-ace";
import ThemeContext from '../ThemeContext';
import "./AddressDetails.css"
import ContractFunctions from '../components/ContractFunctions';
import ContractWrite from '../components/ContractWrite';
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";


const AddressDetails = () => {
    const [mostRecentTxs, setMostRecentTxs] = useState(null);
    const [mostRecentTokenTxs, setMostRecentTokenTxs] = useState(null);
    const [mostRecentNftTxs, setMostRecentNftTxs] = useState(null);
    const [mostRecentErc1155Txs, setMostRecentErc1155Txs] = useState(null);
    const [activeTab, setActiveTab] = useState('transactions');
    const [networks, setNetworks] = useState([]);
    const [balance, setBalance] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalTokenPages, setTotalTokenPages] = useState(0);
    const [totalNftPages, setTotalNftPages] = useState(0);
    const [totalErc1155Pages, setTotalErc1155Pages] = useState(0);
    const [page, setPage] = useState(1);
    const [tokenPage, setTokenPage] = useState(1);
    const [nftPage, setNftPage] = useState(1);
    const [erc1155Page, setErc1155Page] = useState(1);
    const pageSize = 25;
    const { address, chainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [ens, setEns] = useState(null);
    const [currentTxsCount, setCurrentTxsCount] = useState(0);
    const [currentTokenTxsCount, setCurrentTokenTxsCount] = useState(0);
    const [isContract, setIsContract] = useState(false);
    const [currentNftTxsCount, setCurrentNftTxsCount] = useState(0);
    const [currentErc1155Count, setCurrentErc1155Count] = useState(0);
    const [contractInfo, setContractInfo] = useState(null);
    const [portfolio, setPortfolio] = useState(null);
    const location = useLocation();
    const fragment = location.hash.slice(1);
    const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
    const [contractActiveTab, setContractActiveTab] = useState('code');

    const findHighestBlockAndTxIndex = () => {
        let highestBlockNumber = 0;
        let highestTxIndex = 0;

        if (mostRecentTxs && mostRecentTxs.length > 0) {
            mostRecentTxs.forEach(tx => {
                if (tx.block_number > highestBlockNumber) {
                    highestBlockNumber = tx.block_number;
                    highestTxIndex = tx.tx_index;
                }
            });
        }

        return { highestBlockNumber, highestTxIndex };
    };

    useEffect(() => {
        if (fragment === 'txs') {
            setActiveTab('transactions');
        } else if (fragment === 'tokenTransfers') {
            setActiveTab('tokenTransfers');
        } else if (fragment === 'nft') {
            setActiveTab('nftTransfers');
        } else if (fragment === 'erc1155') {
            setActiveTab('erc1155Transfers');
        } else if (fragment === 'contract') {
            setActiveTab('contract');
        } else if (fragment === 'tokens') {
            setActiveTab('tokens');
        } else if (fragment === 'read') {
            setActiveTab('contract');
            setContractActiveTab('read');
        }
        else if (fragment === 'write') {
            setActiveTab('contract');
            setContractActiveTab('write');
        }
        else if (fragment === 'code') {
            setActiveTab('contract');
            setContractActiveTab('code');
        }
        else if (fragment === 'read-proxy') {
            setActiveTab('contract');
            setContractActiveTab('read-proxy');
        }
        else if (fragment === 'write-proxy') {
            setActiveTab('contract');
            setContractActiveTab('write-proxy');
        }

    }, [fragment]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { highestBlockNumber, highestTxIndex } = findHighestBlockAndTxIndex();
                const networksResponse = await axios.get(`/api/networks`);
                const response = await axios.get(`/api/addresstxs/${chainId}/${address}/${page}/${highestBlockNumber}/${highestTxIndex}`);

                if (response.data && response.data.transactions) {
                    networksResponse.data.forEach((item) => {
                        response.data.transactions.forEach((transaction) => {
                            if (item.chainId === transaction.chain_id) {
                                transaction.chainName = item.chainName;
                                transaction.ticker = item.nativeCurrency.symbol;
                                transaction.logoURI = item.logoURI;
                            }
                        });
                    });

                    setNetworks(networksResponse.data);
                    setMostRecentTxs(response.data.transactions);
                    setCurrentTxsCount(response.data.transactions.length);
                    setIsContract(response.data.is_contract);
                    if (response.data.is_contract === true) {
                        setContractInfo(response.data.contract_info);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [address, chainId, page]);
    /*
        useEffect(() => {
            const fetchTotalPages = async () => {
                const totalPagesResponse = await axios.get(`/api/gettotaltxs/${chainId}/${address}`);
                setTotalPages(totalPagesResponse.data.total_pages);
            };
     
            fetchTotalPages();
        }, [address, chainId]);
    */
    useEffect(() => {
        const fetchTokenData = async () => {
            const response = await axios.get(`/api/addresstokentxs/${chainId}/${address}/${tokenPage}`);
            setMostRecentTokenTxs(response.data);
            setCurrentTokenTxsCount(response.data.length);
        };

        fetchTokenData();
    }, [address, chainId, tokenPage]);
    /*
        useEffect(() => {
            const fetchTokenTotalPages = async () => {
                const totalPagesResponse = await axios.get(`/api/gettotaltokentxs/${chainId}/${address}`);
                setTotalTokenPages(totalPagesResponse.data.total_pages);
            };
     
            fetchTokenTotalPages();
        }, [address, chainId]);
    */
    useEffect(() => {
        const fetchNftData = async () => {
            const response = await axios.get(`/api/addressnfttxs/${chainId}/${address}/${nftPage}`);
            setMostRecentNftTxs(response.data);
            setCurrentNftTxsCount(response.data.length);
        };

        fetchNftData();
    }, [address, chainId, nftPage]);
    /*
        useEffect(() => {
            const fetchNftTotalPages = async () => {
                const totalPagesResponse = await axios.get(`/api/gettotalnfttxs/${chainId}/${address}`);
                setTotalNftPages(totalPagesResponse.data.total_pages);
            };
     
            fetchNftTotalPages();
        }, [address, chainId]);
    */

    useEffect(() => {
        const fetchErc1155Data = async () => {
            const response = await axios.get(`/api/addresserc1155txs/${chainId}/${address}/${erc1155Page}`);
            setMostRecentErc1155Txs(response.data);
            setCurrentErc1155Count(response.data.length);
        };

        fetchErc1155Data();
    }, [address, chainId, erc1155Page]);

    // Fetch balance
    useEffect(() => {
        const fetchBalance = async () => {
            const response = await axios.get(`/api/getbalance/${chainId}/${address}`);
            setBalance(response.data.result);
        };

        fetchBalance();
    }, [address, chainId]);

    // Fetch ENS name
    useEffect(() => {
        const fetchEnsName = async () => {
            const response = await axios.get(`/api/getens/${address}/${chainId}`);
            setEns(response.data.result);
        };

        fetchEnsName();
    }, [address, chainId]);

    // Fetch portfolio
    useEffect(() => {
        const fetchPortfolio = async () => {
            const response = await axios.get(`/api/gettokenbalances/${chainId}/${address}`);
            setPortfolio(response.data.result);
            setLoading(false);
        };

        fetchPortfolio();
    }, [address, chainId]);

    useEffect(() => {
        document.title = "vfat scan - " + chainId + " - " + address;
    }, [chainId, address]);

    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    const switchContractTab = (tab, tabFragment) => {
        setContractActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    const goToLastPage = () => {
        setPage(totalPages);
    };

    const goToFirstPage = () => {
        setPage(1);
    };

    const changePage = (increment) => {
        setPage(prevPage => prevPage + increment);
    };

    const goToTokenLastPage = () => {
        setTokenPage(totalTokenPages);
    };

    const goToTokenFirstPage = () => {
        setTokenPage(1);
    };

    const changeTokenPage = (increment) => {
        setTokenPage(prevTokenPage => prevTokenPage + increment);
    };

    const goToNftLastPage = () => {
        setNftPage(totalNftPages);
    };

    const goToNftFirstPage = () => {
        setNftPage(1);
    };

    const changeNftPage = (increment) => {
        setNftPage(prevNftPage => prevNftPage + increment);
    };

    const goToErc1155LastPage = () => {
        setErc1155Page(totalErc1155Pages);
    };

    const goToErc1155FirstPage = () => {
        setErc1155Page(1);
    };

    const changeErc1155Page = (increment) => {
        setErc1155Page(prevErc1155Page => prevErc1155Page + increment);
    };

    const resetPage = () => {
        setPage(1);
        setTokenPage(1);
        setNftPage(1);
    };

    useEffect(() => {
        const editorElements = document.querySelectorAll('.ace_gutter');

        if (editorElements && contractInfo && activeTab === 'contract') {
            editorElements.forEach(element => {
                element.style.backgroundColor = isDarkTheme ? 'oklch(var(--n))' : '';
                element.style.color = isDarkTheme ? '#e5e5e5' : '';
            });
        }
    }, [isDarkTheme, contractInfo, activeTab]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    return (
        <>
            <div className="card w-full bg-base-100 shadow-xl">
                <div className="card-body">
                    <div className="text-sm breadcrumbs">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Address</li>
                        </ul>
                    </div>
                    <h4 className="card-title">Address Details</h4>
                    <div className="break-words">
                        {address}&nbsp;
                        <CopyButton text={address} />
                    </div>
                    <div className="divider" />
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/6 p-3">
                            <strong>Balance</strong>
                        </div>
                        <div className="w-full md:w-5/6 p-3">
                            <span>{balance.toFixed(18)}&nbsp;{mostRecentTxs && mostRecentTxs.length > 0 ? mostRecentTxs[0].ticker : ''}</span>
                        </div>
                        {ens && ens.length > 0 &&
                            <>
                                <div className="w-full md:w-1/6 p-3">
                                    <strong>ENS</strong>
                                </div>
                                <div className="w-full md:w-5/6 p-3">
                                    <span>{ens[0].name}&nbsp;<CopyButton text={ens[0].name} /></span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div role="tablist" className="tabs tabs-lifted mt-5" style={{ display: 'inline-block' }} >
                <a
                    role='tab'
                    className={`tab ${activeTab === "transactions" ? "tab-active" : ""}`}
                    onClick={() => switchTab("transactions", "txs")}
                >
                    Transactions
                </a>
                <a
                    role='tab'
                    className={`tab ${activeTab === "tokenTransfers" ? "tab-active" : ""}`}
                    onClick={() => switchTab("tokenTransfers", "tokenTransfers")}
                >
                    Token Transfers
                </a>
                <a
                    role='tab'
                    className={`tab ${activeTab === "nftTransfers" ? "tab-active" : ""}`}
                    onClick={() => switchTab("nftTransfers", "nft")}
                >
                    NFT Transfers
                </a>
                <a
                    role='tab'
                    className={`tab ${activeTab === "tokens" ? "tab-active" : ""}`}
                    onClick={() => switchTab("tokens", "tokens")}
                >
                    Tokens
                </a>
                <a

                    role='tab'
                    className={`tab ${activeTab === "erc1155Transfers" ? "tab-active" : ""}`}
                    onClick={() => switchTab("erc1155Transfers", "erc1155")}
                >
                    ERC1155 Transfers
                </a>
                {isContract && contractInfo &&
                    <a className={`tab ${activeTab === "contract" ? "tab-active" : ""}`} onClick={() => switchTab("contract", "contract")}>
                        Contract&nbsp;{contractInfo.contract_abi && <div className="badge badge-success badge-xs"></div>}
                    </a>
                }
            </div>
            {activeTab === 'transactions' && mostRecentTxs &&
                <>
                    <AddressTxs mostRecentTxs={mostRecentTxs} address={address} resetPage={resetPage} />
                    <div className="flex justify-between my-10">
                        <div className="join">
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToFirstPage} disabled={page <= 1}><MdKeyboardDoubleArrowLeft /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(-1)} disabled={page <= 1}><MdKeyboardArrowLeft /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(-1)} disabled={page <= 1}><MdKeyboardArrowLeft /></button>
                            <input
                                type="radio"
                                name="options"
                                aria-label={page}
                                className="btn btn-neutral btn-xs btn-outline join-item"
                            />
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(1)} disabled={!(page < totalPages || totalPages === 400)}><MdKeyboardArrowRight /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToLastPage} disabled={page >= totalPages || currentTxsCount < pageSize}><MdKeyboardDoubleArrowRight /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(1)} disabled={mostRecentTxs.length < 25}><MdKeyboardArrowRight /></button>
                        </div>
                        {mostRecentTxs && mostRecentTxs.length > 0 &&
                            <CSVExportButton data={mostRecentTxs} filename="export.csv" />
                        }
                    </div>
                </>}
            {activeTab === 'tokenTransfers' && mostRecentTokenTxs &&
                <>
                    <AddressTokenTxs mostRecentTokenTxs={mostRecentTokenTxs} address={address} resetPage={resetPage} />
                    <div className="flex justify-between my-10">
                        <div className="join">
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToTokenFirstPage} disabled={tokenPage <= 1}><MdKeyboardDoubleArrowLeft /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeTokenPage(-1)} disabled={tokenPage <= 1}><MdKeyboardArrowLeft /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeTokenPage(-1)} disabled={tokenPage <= 1}><MdKeyboardArrowLeft /></button>
                            <input
                                type="radio"
                                name="options"
                                aria-label={tokenPage}
                                className="btn btn-neutral btn-xs btn-outline join-item"
                            />
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeTokenPage(1)} disabled={!(tokenPage < totalTokenPages || totalTokenPages === 800)}><MdKeyboardArrowRight /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToTokenLastPage} disabled={tokenPage >= totalTokenPages || currentTokenTxsCount < pageSize}><MdKeyboardDoubleArrowRight /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeTokenPage(1)} disabled={mostRecentTokenTxs.length < 25}><MdKeyboardArrowRight /></button>
                        </div>
                        {mostRecentTokenTxs && mostRecentTokenTxs.length > 0 &&
                            <CSVExportButton data={mostRecentTokenTxs} filename="export.csv" />
                        }
                    </div>
                </>}
            {activeTab === 'nftTransfers' && mostRecentNftTxs &&
                <>
                    <AddressNftTxs mostRecentNftTxs={mostRecentNftTxs} address={address} resetPage={resetPage} />
                    <div className="flex justify-between my-10">
                        <div className="join">
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToNftFirstPage} disabled={nftPage <= 1}><MdKeyboardDoubleArrowLeft /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeNftPage(-1)} disabled={nftPage <= 1}><MdKeyboardArrowLeft /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeNftPage(-1)} disabled={nftPage <= 1}><MdKeyboardArrowLeft /></button>
                            <input
                                type="radio"
                                name="options"
                                aria-label={nftPage}
                                className="btn btn-neutral btn-xs btn-outline join-item"
                            />
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeNftPage(1)} disabled={!(nftPage < totalNftPages || totalNftPages === 800)}><MdKeyboardArrowRight /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToNftLastPage} disabled={nftPage >= totalNftPages || currentNftTxsCount < pageSize}><MdKeyboardDoubleArrowRight /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeNftPage(1)} disabled={mostRecentNftTxs.length < 25}><MdKeyboardArrowRight /></button>
                        </div>
                        {mostRecentNftTxs && mostRecentNftTxs.length > 0 &&
                            <CSVExportButton data={mostRecentNftTxs} filename="export.csv" />
                        }
                    </div>
                </>}
            {activeTab === 'erc1155Transfers' && mostRecentErc1155Txs &&
                <>
                    <AddressErc1155Txs mostRecentErc1155Txs={mostRecentErc1155Txs} address={address} resetPage={resetPage} />
                    <div className="flex justify-between my-10">
                        <div className="join">
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToErc1155FirstPage} disabled={erc1155Page <= 1}><MdKeyboardDoubleArrowLeft /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeErc1155Page(-1)} disabled={erc1155Page <= 1}><MdKeyboardArrowLeft /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeErc1155Page(-1)} disabled={erc1155Page <= 1}><MdKeyboardArrowLeft /></button>
                            <input
                                type="radio"
                                name="options"
                                aria-label={erc1155Page}
                                className="btn btn-neutral btn-xs btn-outline join-item"
                            />
                            {/*
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeErc1155Page(1)} disabled={!(erc1155Page < totalErc1155Pages || totalErc1155Pages === 800)}><MdKeyboardArrowRight /></button>
                            <button className="btn btn-neutral btn-xs join-item" onClick={goToErc1155LastPage} disabled={erc1155Page >= totalErc1155Pages || currentErc1155Count < pageSize}><MdKeyboardDoubleArrowRight /></button>
                            */}
                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changeErc1155Page(1)} disabled={mostRecentErc1155Txs.length < 25}><MdKeyboardArrowRight /></button>
                        </div>
                        {mostRecentErc1155Txs && mostRecentErc1155Txs.length > 0 &&
                            <CSVExportButton data={mostRecentErc1155Txs} filename="export.csv" />
                        }
                    </div>
                </>}
            {activeTab === 'tokens' &&
                <>
                    <Portfolio tokens={portfolio} chainId={chainId} />
                </>
            }

            {activeTab === 'contract' && contractInfo &&
                <>
                    <div role="tablist" className="tabs tabs-boxed mt-7">
                        <a role="tab" onClick={() => switchContractTab("code", "code")} className={`tab ${contractActiveTab === "code" ? "tab-active" : ""}`}>Code</a>
                        <a role="tab" onClick={() => switchContractTab("read", "read")} className={`tab ${contractActiveTab === "read" ? "tab-active" : ""}`}>Read</a>
                        <a role="tab" onClick={() => switchContractTab("write", "write")} className={`tab ${contractActiveTab === "write" ? "tab-active" : ""}`}>Write</a>
                        {contractInfo.is_proxy && (<> <a role="tab" onClick={() => switchContractTab("read-proxy", "read-proxy")} className={`tab ${contractActiveTab === "read-proxy" ? "tab-active" : ""}`}>Read as Proxy</a>
                            <a role="tab" onClick={() => switchContractTab("write-proxy", "write-proxy")} className={`tab ${contractActiveTab === "write-proxy" ? "tab-active" : ""}`}>Write as Proxy</a></>)}
                    </div>
                    {!contractInfo.contract_abi && (
                        <>
                            <div className="break-all sm:mt-2">
                                <p className="mt-5 text-sm"><kbd className="kbd kbd-sm">i</kbd>&nbsp;Are you the creator of this contract?</p>
                                <p className='mt-5 text-sm'>&nbsp;&nbsp;&nbsp;&nbsp;You can&nbsp;<Link to={`/verify/${chainId}/${address}`} className='link link-info link-hover'>verify and publish</Link>&nbsp;it.</p>
                            </div>
                            <div className="divider"></div>
                        </>
                    )}
                    {contractActiveTab === 'code' &&
                        <>
                            {
                                contractInfo.contract_source_code === null ?
                                    null :
                                    (typeof contractInfo.contract_source_code === 'string' ?
                                        <>
                                            {!contractInfo.is_proxy && (<><div className="break-all sm:mt-2">
                                                <p className="mt-5 text-sm"><kbd className="kbd kbd-sm">i</kbd>&nbsp;Is this contract a proxy?</p>
                                                <p className='mt-5 text-sm'>&nbsp;&nbsp;&nbsp;&nbsp;You can&nbsp;<Link to={`/verify-proxy/${chainId}/${address}`} className='link link-info link-hover'>verify</Link>&nbsp;it.</p>
                                            </div>
                                                <div className="divider"></div></>)}
                                            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 my-5'>
                                                <div>
                                                    <p><strong>EVM version: </strong>{contractInfo.evm_version}</p>
                                                </div>
                                                <div>
                                                    <p><strong>Optimized: </strong>{contractInfo.is_optimized ? ('Yes') : ('No')}</p>
                                                </div>
                                                <div>
                                                    <p><strong>Optimization runs: </strong>{contractInfo.optimization_runs}</p>
                                                </div>
                                                <div>
                                                    <p><strong>Compiler version: </strong>{contractInfo.solc_version}</p>
                                                </div>
                                            </div>
                                            <label className="label my-5">
                                                <span className="label-text font-medium">Contract Source Code&nbsp;<CopyButton text={contractInfo.contract_source_code} /></span>
                                            </label>
                                            <AceEditor
                                                mode="csharp"
                                                theme="github"
                                                name="contractSourceCode"
                                                value={contractInfo.contract_source_code}
                                                readOnly={true}
                                                width='100%'
                                                height='500px'
                                                showPrintMargin={false}
                                                style={{ backgroundColor: isDarkTheme ? 'oklch(var(--n))' : '', color: isDarkTheme ? '#e5e5e5' : '', }}
                                                highlightActiveLine={false}
                                                editorProps={{ $blockScrolling: true, $hScrollBarAlwaysVisible: false, $vScrollBarAlwaysVisible: false }}
                                            />
                                        </>
                                        :
                                        Object.keys(contractInfo.contract_source_code).map((fileKey, index) => (
                                            <>
                                                <label className="label my-5">
                                                    <span className="label-text font-medium">
                                                        Contract Source Code - {fileKey}&nbsp;
                                                        <CopyButton text={JSON.stringify(contractInfo.contract_source_code[fileKey].content, null, 2)} />
                                                    </span>
                                                </label>
                                                <AceEditor
                                                    key={index}
                                                    mode="csharp"
                                                    theme="github"
                                                    name={`contractSourceCode_${fileKey}`}
                                                    value={contractInfo.contract_source_code[fileKey].content}
                                                    readOnly={true}
                                                    width='100%'
                                                    height='500px'
                                                    showPrintMargin={false}
                                                    style={{ backgroundColor: isDarkTheme ? 'oklch(var(--n))' : '', color: isDarkTheme ? '#e5e5e5' : '', }}
                                                    highlightActiveLine={false}
                                                    editorProps={{ $blockScrolling: true, $hScrollBarAlwaysVisible: false, $vScrollBarAlwaysVisible: false }}
                                                />
                                            </>
                                        ))
                                    )
                            }

                            {contractInfo.contract_abi &&
                                <>
                                    <label className="label my-5">
                                        <span className="label-text font-medium">Contract ABI&nbsp;<CopyButton text={JSON.stringify(contractInfo.contract_abi, null, 2)} /></span>
                                    </label>
                                    <AceEditor
                                        mode="json5"
                                        theme="github"
                                        name="contractInfo"
                                        editorProps={{ $blockScrolling: true, $hScrollBarAlwaysVisible: false, $vScrollBarAlwaysVisible: false }}
                                        value={JSON.stringify(contractInfo.contract_abi, null, 2)}
                                        readOnly={true}
                                        width='100%'
                                        height='500px'
                                        showPrintMargin={false}
                                        style={{ backgroundColor: isDarkTheme ? 'oklch(var(--n))' : '', color: isDarkTheme ? '#e5e5e5' : '', }}
                                        highlightActiveLine={false}
                                    />
                                </>
                            }
                            <div className="form-control my-5">
                                <label className="label mb-3">
                                    <span className="label-text font-medium">Contract Creation Code&nbsp;<CopyButton text={contractInfo.tx_data} /></span>
                                </label>
                                <textarea className="textarea textarea-bordered h-72" readOnly>{contractInfo.tx_data}</textarea>
                            </div>
                            <div className="form-control my-5">
                                <label className="label mb-3">
                                    <span className="label-text font-medium">Deployed Bytecode&nbsp;<CopyButton text={contractInfo.contract_code} /></span>
                                </label>
                                <textarea className="textarea textarea-bordered h-72" readOnly>{contractInfo.contract_code}</textarea>
                            </div>

                        </>
                    }
                    {contractActiveTab === 'read' &&
                        <>
                            <ContractFunctions contractInfo={contractInfo} network={chainId} address={address} />
                        </>
                    }
                    {contractActiveTab === 'write' &&
                        <>
                            <ContractWrite contractInfo={contractInfo} network={chainId} address={address} />
                        </>
                    }
                    {contractActiveTab === 'read-proxy' &&
                        <>
                            <ContractFunctions contractInfo={contractInfo.proxied_abi} network={chainId} address={contractInfo.proxied_address} />
                        </>
                    }
                    {contractActiveTab === 'write-proxy' &&
                        <>
                            <ContractWrite contractInfo={contractInfo.proxied_abi} network={chainId} address={contractInfo.proxied_address} />
                        </>
                    }
                </>
            }
        </>
    );
};

export default AddressDetails;
