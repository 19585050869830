import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import ChainTokens from '../components/tables/ChainTokens';

function Token() {
    const { symbol } = useParams();
    const { chainId } = useParams();
    const [networks, setNetworks] = useState(null);
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [transformedNetworks, setTransformedNetworks] = useState(null);
    const [tokens, setTokens] = useState(null);
    const preloadImages = (tokens) => {
        tokens.result.forEach(token => {
            if (!token.logo || token.logo === null) {
                return;
            }
            const img = new Image();
            img.src = token.logo;
        });
    };
    useEffect(() => {
        const fetchNetworks = async () => {
            try {
                const response = await axios.get('/api/networks');
                setNetworks(response.data);
            } catch (error) {
                console.error('Failed to fetch networks', error);
            }
        };

        fetchNetworks();
    }, []);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const response = await axios.get(`/api/gettoken/${chainId}/${symbol.toUpperCase()}`);
                preloadImages(response.data);
                setTokens(response.data);
            } catch (error) {
                console.error('Failed to fetch tokens', error);
            }
        };

        fetchTokens();
    }, [chainId, symbol]);

    useEffect(() => {
        if (networks && tokens) {
            const transformedNetworks = networks.map((network) => {
                const token = tokens.result.find((token) => token.chainId === network.chainId);
                return {
                    ...network,
                    token,
                };
            });
            setTransformedNetworks(transformedNetworks);
            setLoading(false);
        }
    }, [networks, tokens]);

    useEffect(() => {
        if (chainId && symbol) {
            document.title = "vfat scan - " + chainId + " - " + symbol;
        }
    }, [chainId, symbol]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    return (
        <><div className="card w-full bg-base-100 shadow-xl mb-7">
            <div className="card-body">
                <div className="text-sm breadcrumbs">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li>Tokens Found</li>
                    </ul>
                </div>
                {tokens && tokens.result.length === 0 && <h4 className="card-title">No Tokens Found</h4>}
                {tokens && tokens.result.length === 1 && <h4 className="card-title">Found {tokens.result.length} Matching Result for {symbol} </h4>}
                {tokens && tokens.result.length > 1 && <h4 className="card-title">Found {tokens.result.length} Matching Results for {symbol}</h4>}
            </div>
        </div>
            <ChainTokens tokens={tokens} />
        </>
    );
}

export default Token;