import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CopyButton from "./CopyButton";
import BigNumber from "bignumber.js";
import { PropagateLoader } from "react-spinners";

const LogsList = ({ logs, chainId }) => {
    const [decodeStates, setDecodeStates] = useState(
        logs ? logs.map(() => ({
            topic1: 'hex',
            topic2: 'hex',
            topic3: 'hex',
            log_data: 'hex',
        })) : []
    );
    const [positions, setPositions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (logs && logs.length > 0) {
            const newPositions = logs.map(log => {
                let currentPosition = 0;
                let positionObj = {};

                if (log.topic1 !== "0x") {
                    positionObj.topic1 = currentPosition++;
                }
                if (log.topic2 !== "0x") {
                    positionObj.topic2 = currentPosition++;
                }
                if (log.topic3 !== "0x") {
                    positionObj.topic3 = currentPosition++;
                }
                if (log.data !== "0x") {
                    positionObj.data = currentPosition;
                }
                if (log.topic0_decoded) {
                    positionObj.call = parseCall(log.topic0_decoded);
                }

                return positionObj;
            });

            setPositions(newPositions);
            setLoading(false);
        }
    }, [logs]);

    const handleDecodeChange = (logIndex, field, value) => {
        setDecodeStates(decodeStates =>
            decodeStates.map((state, index) =>
                index === logIndex ? { ...state, [field]: value } : state
            )
        );
    };
    // TODO decode arrays https://scan.vf.at/block/148/2204066#logs
    const decodeValue = (value, type, isDecoded, logIndex, position) => {
        if (type === undefined) {
            return value;
        }
        if (type === 'bytes') {
            return value; //todo handle bytes
        }
        if (value.length > 66) {
            value = splitHex(value)
        }
        if (isDecoded) {
            if (typeof value === 'object') {
                const decodedLogs = [];
                for (let i = 0; i < value.length; i++) {
                    decodedLogs.push(Object.values(logs[logIndex].decoded_log)[position++]);
                }
                return decodedLogs;
            }
            const decodedLog = Object.values(logs[logIndex].decoded_log)[position];
            return decodedLog;
        } else {
            if (typeof value === 'object') {
                let resultArray = [];
                for (let i = 0; i < value.length; i++) {
                    resultArray.push(processSingleValue(value[i], positions[logIndex].call[position++]));
                }
                return resultArray;
            }
            return processSingleValue(value, type);
        }
    };

    const processSingleValue = (value, type) => {
        if (type === undefined) {
            return value;
        }
        if (type === 'string') {
            return hexToReadableString(value);
        }
        if (type === 'bool') {
            if (value.endsWith('0')) {
                return 'false';
            }
            if (value.endsWith('1')) {
                return 'true';
            }
        }
        if (type === 'address') {
            return '0x' + value.slice(value.length - 40);
        }

        if (type.includes('uint') || type.includes('int')) {
            return new BigNumber(value).toFixed();
        }

        if (type === 'bytes32') {
            return value;
        }
        if (type === 'bytes') {
            const dataLength = parseInt(value.slice(64, 128), 16) * 2;
            const data = value.slice(128, 128 + dataLength);
            return data;
        }
    };

    const parseCall = (callString) => {
        const insideParentheses = callString.split('(')[1].split(')')[0];

        const transactionArguments = insideParentheses.split(',');

        return transactionArguments;
    }

    const isAlreadyDecoded = (logIndex) => {
        const log = logs[logIndex];
        if (log.decoded_log) {
            return true;
        }

        return false;
    }

    function hexToReadableString(hex) {
        if (hex.startsWith('0x')) {
            hex = hex.slice(2);
        }

        var str = '';
        for (var i = 0; i < hex.length; i += 2) {
            var charCode = parseInt(hex.substr(i, 2), 16);
            if (!isNaN(charCode) && charCode !== 0) {
                str += String.fromCharCode(charCode);
            }
        }
        return str;
    }

    function splitHex(hexString) {
        if (hexString.startsWith('0x')) {
            hexString = hexString.slice(2);
        }

        if (hexString.length % 64 !== 0 || hexString.length < 64) {
            throw new Error('Invalid hex string length. Length must be a multiple of 64 characters.');
        }

        var result = [];
        for (var i = 0; i < hexString.length; i += 64) {
            result.push('0x' + hexString.slice(i, i + 64));
        }

        return result;
    }

    if (loading) {
        return (<div className="grid h-screen place-items-center">
            <PropagateLoader color="#f1fa89" />
        </div>)
    }

    return (
        <div className="mb-7">
            {logs && logs.length > 0 ? (
                logs.map((log, index) => (
                    <div key={index} className="card w-full bg-base-200 bg-opacity-20 text-base-content mt-1">
                        <div className="card-body">
                            <div className="flex flex-col sm:flex-row items-start">
                                <div className="flex-none flex-grow-0 flex-shrink-0 order-first">
                                    <button className="btn btn-sm btn-square btn-outline btn-primary no-animation enabled:pointer-events-none mr-7 mb-3">
                                        {log.log_index}
                                    </button>
                                </div>
                                <div className="flex-none flex-grow-0 flex-shrink-0">
                                    <strong>Address</strong>
                                </div>
                                <div className="flex-auto w-full sm:flex-col sm:ml-5">
                                    <div className="break-all sm:mb-2">
                                        <Link
                                            className="link link-info link-hover"
                                            to={`/address/${chainId}/${log.address}`}
                                        >
                                            {log.address}
                                        </Link>&nbsp;
                                        <CopyButton text={log.address} />
                                    </div>
                                    {log.topic0_decoded && (
                                        <>
                                            <div className="flex-none flex-grow-0 flex-shrink-0 mt-2">
                                                <p className="text">Call</p>
                                            </div>
                                            <div className="break-all sm:mt-2">
                                                <p className="text-sm mt-1">
                                                    {log.topic0_decoded}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    <div className="flex-none flex-grow-0 flex-shrink-0 mt-2">
                                        <p className="text">Topics</p>
                                    </div>
                                    <div className="break-all sm:mt-2">
                                        <p className="text-sm mt-1">
                                            <kbd className="kbd kbd-sm">0</kbd> {log.topic0}
                                        </p>
                                        {log.topic1 !== "0x" && positions[index] && (
                                            <div>
                                                <p className="text-sm mt-1 lg:mr-4">
                                                    <kbd className="kbd kbd-sm">1</kbd>&nbsp;{log.decoded_log && (<kbd className="kbd kbd-sm">{Object.keys(log.decoded_log)[positions[index].topic1]}</kbd>)}
                                                    {log.topic0_decoded && decodeStates[index] && (
                                                        <>
                                                            <select
                                                                className="select select-bordered select-xs w-24 max-w-xs lg:mt-0 text-xs mx-3"
                                                                value={decodeStates[index].topic1}
                                                                onChange={(e) => handleDecodeChange(index, 'topic1', e.target.value)}
                                                            >
                                                                <option value="hex">Hex</option>
                                                                <option value="decoded">Decode</option>
                                                            </select>
                                                            {decodeStates[index].topic1 === 'decoded' ? decodeValue(log.topic1, positions[index].call[positions[index].topic1], isAlreadyDecoded(index), index, positions[index].topic1) : log.topic1}
                                                        </>
                                                    )}
                                                    {!log.topic0_decoded && log.topic1}
                                                </p>
                                            </div>
                                        )}
                                        {log.topic2 !== "0x" && positions[index] && (
                                            <div>
                                                <p className="text-sm mt-1 lg:mr-4">
                                                    <kbd className="kbd kbd-sm">2</kbd>&nbsp;{log.decoded_log && (<kbd className="kbd kbd-sm">{Object.keys(log.decoded_log)[positions[index].topic2]}</kbd>)}
                                                    {log.topic0_decoded && decodeStates[index] && (
                                                        <>
                                                            <select
                                                                className="select select-bordered select-xs w-24 max-w-xs lg:mt-0 text-xs mx-3"
                                                                value={decodeStates[index].topic2}
                                                                onChange={(e) => handleDecodeChange(index, 'topic2', e.target.value)}
                                                            >
                                                                <option value="hex">Hex</option>
                                                                <option value="decoded">Decode</option>
                                                            </select>
                                                            {decodeStates[index].topic2 === 'decoded' ? decodeValue(log.topic2, positions[index].call[positions[index].topic2], isAlreadyDecoded(index), index, positions[index].topic2) : log.topic2}
                                                        </>
                                                    )}
                                                    {!log.topic0_decoded && log.topic2}
                                                </p>
                                            </div>
                                        )}
                                        {log.topic3 !== "0x" && positions[index] && (
                                            <div>
                                                <p className="text-sm mt-1 lg:mr-4">
                                                    <kbd className="kbd kbd-sm">3</kbd>&nbsp;{log.decoded_log && (<kbd className="kbd kbd-sm">{Object.keys(log.decoded_log)[positions[index].topic3]}</kbd>)}
                                                    {log.topic0_decoded && decodeStates[index] && (
                                                        <>
                                                            <select
                                                                className="select select-bordered select-xs w-24 max-w-xs lg:mt-0 text-xs mx-3"
                                                                value={decodeStates[index].topic1}
                                                                onChange={(e) => handleDecodeChange(index, 'topic3', e.target.value)}
                                                            >
                                                                <option value="hex">Hex</option>
                                                                <option value="decoded">Decode</option>
                                                            </select>
                                                            {decodeStates[index].topic3 === 'decoded' ? decodeValue(log.topic3, positions[index].call[positions[index].topic3], isAlreadyDecoded(index), index, positions[index].topic3) : log.topic3}
                                                        </>
                                                    )}
                                                    {!log.topic0_decoded && log.topic3}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-none flex-grow-0 flex-shrink-0 mt-2">
                                        <p className="text">Data</p>
                                    </div>
                                    {log.topic0_decoded && decodeStates[index] && (
                                        <div className="break-all sm:mt-2">
                                            <p className="text-sm mt-0">
                                                {/*  {log.decoded_log && log.topic0_decoded && positions && positions[index] && (<kbd className="kbd kbd-sm">{Object.keys(log.decoded_log)[positions[index].data]}</kbd>)} */}
                                                {
                                                    decodeStates[index].log_data === 'decoded'
                                                        ? (() => {
                                                            const decodedValue = decodeValue(log.data, positions[index].call[positions[index].data], isAlreadyDecoded(index), index, positions[index].data);
                                                            if (typeof decodedValue === 'object' && decodedValue !== null) {
                                                                let cleanString = JSON.stringify(decodedValue, null, 2);
                                                                cleanString = cleanString.replace(/[\[\]",]+/g, ''); // Removes brackets and quotes
                                                                return cleanString.split('\n').map((line, i) => <div key={i}>{line.trim()}</div>);
                                                            } else {
                                                                if (typeof decodedValue === 'object') {
                                                                    let cleanString = JSON.stringify(decodedValue, null, 2);
                                                                    cleanString = cleanString.replace(/[\[\]",]+/g, '');
                                                                    return cleanString.split('\n').map((line, i) => <div key={i}>{line.trim()}</div>);
                                                                }
                                                                return decodedValue;
                                                            }
                                                        })()
                                                        : log.data
                                                }
                                            </p>
                                            {positions[index] && (
                                                <select
                                                    className="select select-bordered select-xs w-24 max-w-xs lg:mt-2 text-xs"
                                                    value={decodeStates[index].log_data}
                                                    onChange={(e) => handleDecodeChange(index, 'log_data', e.target.value)}
                                                >
                                                    <option value="hex">Hex</option>
                                                    <option value="decoded">Decode</option>
                                                </select>
                                            )}
                                        </div>
                                    )}
                                    {!log.topic0_decoded && (
                                        <div className="break-all sm:mt-2">
                                            <p className="text-sm mt-0">{log.data}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center mt-5">No logs found</p>
            )}
        </div>
    );
}

export default LogsList;

