import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BlocksTable = ({ txs, chainId }) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const truncateTxHash = (txHash) => {
        return isLargeScreen ? txHash : txHash.slice(0, 4) + "..." + txHash.slice(-4);
    };

    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-pin-cols table-zebra table-sm w-full font-primary">
                <thead>
                    <tr>
                        <th>#</th>
                        <td>Hash</td>
                        <td>From</td>
                        <td>To</td>
                        <td>Value ({txs.ticker})</td>
                    </tr>
                </thead>
                <tbody>
                    {txs.length === 0 ?
                        <tr>
                            <td colSpan="4" className="text-center">
                                No transactions found
                            </td>
                        </tr>
                        :
                        txs.map((tx, index) => (
                            <tr key={index}>
                                <th>{tx.tx_index}</th>
                                <td>
                                    <Link className="link link-info link-hover" to={`/tx/${tx.chain_id}/${tx.transaction_hash}`}>{truncateTxHash(tx.transaction_hash)}</Link>
                                </td>
                                <td><Link className="link link-info link-hover" to={`/address/${chainId}/${tx.from_address}`}>{tx.from_address}</Link></td>
                                <td><Link className="link link-info link-hover" to={`/address/${chainId}/${tx.to_address}`}>{tx.to_address}</Link></td>
                                <td>{tx.value.toFixed(7)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default BlocksTable;
