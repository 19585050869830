import React from 'react';
import { Link } from 'react-router-dom';
import { TbSquareRoundedLetterT } from 'react-icons/tb';

const ChainTokens = ({ tokens }) => {
    return (
        <div className="overflow-x-auto mb-7">
            <table className="table table-zebra table-sm w-full">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Verified</th>
                    </tr>
                </thead>
                <tbody>
                    {tokens.result && tokens.result.length > 0 ?
                        tokens.result.map((token) => (
                            <tr key={token.token_address}>
                                <th>
                                    {!token.logo || token.logo === null ? <TbSquareRoundedLetterT className="w-6 h-6 mr-2 inline-block rounded-full" /> : <img src={token.logo} alt={token.name} className="w-6 h-6 mr-2 inline-block rounded-full" />}
                                    <Link
                                        className="link link-info link-hover"
                                        to={`/token/${token.chain_id}/${token.symbol_uppercased}/${token.token_address}`}
                                    >
                                        {token.symbol_uppercased}&nbsp;({token.name})
                                    </Link>
                                </th>
                                <td>
                                    {token.token_address}
                                </td>
                                <td>{token.is_whitelisted === true ? <div className="badge badge-xs badge-success"></div> : <div className="badge badge-xs badge-error"></div>}</td>
                            </tr>
                        )) :
                        <tr>
                            <td colSpan="5" className="text-center">
                                No Tokens Found
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default ChainTokens;