import React, { useState } from 'react';
import { BsClipboard2, BsCheck2 } from 'react-icons/bs';

const CopyButton = ({ text }) => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 2000);
        navigator.clipboard.writeText(text);
    }

    return (
        <div className="tooltip" data-tip="Copy" onClick={handleClick}>
            {clicked ? (
                <BsCheck2 />
            ) : (
                <BsClipboard2 />
            )}
        </div>
    );
}

export default CopyButton;
