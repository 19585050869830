import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import PoolsDetailTable from '../components/tables/PoolsDetailTable';

function PoolsDetail() {
    const { symbol } = useParams();
    const { address } = useParams();
    const { chainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [liquidity, setLiquidity] = useState(null);
    const preloadImages = (pools) => {
        pools.result.pools.forEach(pool => {
            const img = new Image();
            img.src = pool.logoURI;
        });
    };
    useEffect(() => {

        const fetchLiquidity = async () => {
            const response = await axios.get(`/api/getliquidity/${chainId}/${address}`);
            const { data } = response;
            const urlSymbols = symbol.split('_');

            data.result.pools = data.result.pools.filter(pool => {
                const poolSymbols = pool.underlying.map(token => token.token.symbol);
                return poolSymbols.length === urlSymbols.length && poolSymbols.every(poolSymbol => urlSymbols.includes(poolSymbol));
            });
            preloadImages(data);
            setLiquidity(data);
        };

        Promise.all([fetchLiquidity()])
            .then(() => setLoading(false))
            .catch((error) => console.error('Failed to fetch data', error));
    }, [chainId, address, symbol]);

    useEffect(() => {
        document.title = "vfat scan - " + symbol;
    }, [symbol]);

    if (loading) {
        return (
            <div className="grid h-screen place-items-center">
                <PropagateLoader color="#f1fa89" />
            </div>
        );
    }

    if (!loading && liquidity) {
        const urlSymbols = symbol.split('_');
        const tokenLogos = urlSymbols.map(urlSymbol => {
            const token = liquidity.result.pools[0].underlying.find(token => token.token.symbol === urlSymbol);
            return token ? token.token.logoURI : 'default_logo_uri';
        });


        return (
            <>
                <div className="card w-full bg-base-100 shadow-xl mb-7">
                    <div className="card-body">
                        <div className="text-sm breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Pools Detail</li>
                            </ul>
                        </div>
                        {liquidity && liquidity.result.pools.length > 0 &&
                            <>
                                <div className="flex items-center">
                                    {tokenLogos.map((logo, index) => (
                                        <img src={logo} alt={`logo-${index}`} className="h-6 w-6 mr-2" />
                                    ))}
                                    <h4 className="card-title">{symbol}</h4>
                                </div>
                            </>}
                    </div>
                </div>

                {liquidity && liquidity.result.pools.length > 0 &&
                    <PoolsDetailTable data={liquidity} />
                }
            </>
        );
    }
}

export default PoolsDetail;
