import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import CopyButton from '../components/CopyButton';
import ThemeContext from '../ThemeContext';
import SolidityFlattened from '../components/SolidityFlattened';
import SolidityStandardJSON from '../components/SolidityStandardJSON';
import VyperFlattened from '../components/VyperFlattened';
import axios from 'axios';
import "./VerifyContracts.css";

function VerifyContract() {
    const { address } = useParams();
    const { chainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [verificationMethod, setVerificationMethod] = useState('');
    const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
    const [isContract, setIsContract] = useState('');
    const [isVerified, setIsVerified] = useState(false);


    useEffect(() => {

        async function fetchContract() {
            try {
                const response = await axios.get(`/api/getcontract/${chainId}/${address}`);
                setIsContract(response.data);
                setIsVerified(response.data.is_verified);
                setLoading(false);

            } catch (error) {
                setIsContract(false);
                setLoading(false);
            }
        }

        fetchContract();
    }, [chainId, address]);

    useEffect(() => {
        document.title = "vfat scan - verify contract";
    }, []);

    const handleVerificationMethodChange = (e) => {
        setVerificationMethod(e.target.value);
    };

    if (isContract === false || isContract.error || isVerified) {
        return (
            <>
                <div className='mt-3'>
                    <h2 className="text-2xl font-bold mt-10">Address is not a contract or contract is verified already</h2>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='mt-3'>
                {loading ? (
                    <div className="grid h-screen place-items-center">
                        <PropagateLoader color="#f1fa89" />
                    </div>
                ) : (
                    <div className='mx-7'>
                        <h2 className="text-2xl font-bold mt-10">New smart contract verification</h2>
                        <p className="text-md mt-7">{address}&nbsp;<CopyButton text={address} /></p>
                        <p className="text-md mt-7">Supported verification methods</p>
                        <select
                            className="select select-bordered w-full max-w-xs mt-7"
                            onChange={handleVerificationMethodChange}
                            value={verificationMethod}
                        >
                            <option value="" disabled selected>Verification method</option>
                            <option value="solidityFlattened">Solidity (flattened source)</option>
                            <option value="solidityStandardJson">Solidity (standard JSON input)</option>
                            <option value="vyperFlattened">Vyper (flattened source)</option>
                        </select>

                        {verificationMethod === 'solidityFlattened' && (
                            <SolidityFlattened address={address} deployedBytecode={isContract.result.contract_code} chainId={chainId} />
                        )}

                        {verificationMethod === 'solidityStandardJson' && (
                            <SolidityStandardJSON isDarkTheme={isDarkTheme} address={address} chainId={chainId} deployedBytecode={isContract.result.contract_code} />
                        )}

                        {verificationMethod === 'vyperFlattened' && (
                            <VyperFlattened address={address} deployedBytecode={isContract.result.contract_code} chainId={chainId} />
                        )}

                    </div>
                )}
            </div>
        </>
    );
}
export default VerifyContract;
