/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CopyButton from '../components/CopyButton';
import BlocksTable from '../components/tables/BlocksTable';
import { useParams, Link, useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import './BlockDetails.css';
import CSVExportButton from '../components/CSVExportButton';
import LogsList from '../components/LogsList';

const BlockDetails = () => {
    const [totalPages, setTotalPages] = useState(0);
    const [networks, setNetworks] = useState([]);
    const { chainId, blockNum } = useParams();
    const [txs, setTxs] = useState([]);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState('overview');
    const [block, setBlock] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const fragment = location.hash.slice(1);

    useEffect(() => {
        if (fragment === 'txs') {
            setActiveTab('transactions');
        } else if (fragment === 'overview') {
            setActiveTab('overview');
        } else if (fragment === 'logs') {
            setActiveTab('logs');
        }
    }, [fragment]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const logsResponse = await axios.get(`/api/blocklogs/${chainId}/${blockNum}`);
                setLogs(logsResponse.data.result);
            } catch (err) {
                console.error(err);
            }
        };

        fetchLogs();
    }, [chainId, blockNum]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const networksResponse = await axios.get(`/api/networks`);
                setNetworks(networksResponse.data);

                const blockHashResponse = await axios.get(`/api/blockbychainid/${chainId}/${blockNum}`);
                if (blockHashResponse.data.result.length === 0) {
                    setErrorMessage("The block cannot be found");
                }
                const response = await axios.get(`/api/blocktxs/${chainId}/${blockNum}/${page}/25`);
                const updatedTxs = response.data;
                const updatedTotalPages = updatedTxs.total_pages;

                networksResponse.data.forEach(item => {
                    if (
                        updatedTxs.result.length > 0 &&
                        item.chainId === updatedTxs.result[0].chain_id
                    ) {
                        updatedTxs.result.chainName = item.chainName;
                        updatedTxs.result.ticker = item.nativeCurrency.symbol;
                        updatedTxs.result.logoURI = item.logoURI;
                    }
                });

                setBlock(blockHashResponse.data);
                setTxs(updatedTxs.result);
                setTotalPages(updatedTotalPages);
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [chainId, blockNum, page]);

    useEffect(() => {
        document.title = "vfat scan - " + chainId + " - Block #" + blockNum;
    }, [blockNum, chainId]);


    const switchTab = (tab, tabFragment) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, `#${tabFragment}`);
    };

    const goToLastPage = () => {
        setPage(totalPages);
    }

    const goToFirstPage = () => {
        setPage(1);
    }

    const changePage = (increment) => {
        setPage(prevPage => prevPage + increment);
    }

    return (
        <>
            {loading ?
                <div className="grid h-screen place-items-center">
                    <PropagateLoader color="#f1fa89" />
                </div>
                :
                <>
                    {errorMessage !== '' ?
                        <p className="text-center mt-5">{errorMessage}</p>
                        :
                        <>
                            <div className="card w-full bg-base-100 shadow-xl mb-7">
                                <div className="card-body">
                                    <div className="text-sm breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li>Block &nbsp;#{blockNum}</li>
                                        </ul>
                                    </div>
                                    <h4 className="card-title">Block Hash</h4>
                                    <div className="break-words">
                                        {block.result.block_hash}&nbsp;
                                        <CopyButton text={block.result.block_hash} />
                                    </div>
                                </div>
                            </div>
                            <div role="tablist" className="tabs tabs-lifted mt-7" style={{ display: 'inline-block' }} >
                                <a role="tab" className={`tab ${activeTab === "overview" ? "tab-active" : ""}`} onClick={() => switchTab("overview", "overview")}>
                                    Overview
                                </a>
                                <a role="tab" className={`tab ${activeTab === "transactions" ? "tab-active" : ""}`} onClick={() => switchTab("transactions", "txs")}>
                                    Transactions
                                </a>
                                <a
                                    role="tab"
                                    className={`tab ${activeTab === "logs" ? "tab-active" : ""} ${logs && logs.length > 0 ? "" : "tab-disabled"}`}
                                    onClick={() => switchTab("logs", "logs")}
                                >
                                    Logs
                                </a>
                            </div>
                            {activeTab === "overview" && (
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Chain ID:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3">
                                        <Link to={`/chain/${chainId}`} className="link link-info link-hover">{chainId}</Link>
                                    </div>

                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Block Number:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3" style={{ display: 'flex', alignItems: 'center' }}>
                                        <Link to={`/block/${chainId}/${parseInt(blockNum, 10) - 1}`} className="link">
                                            <MdKeyboardArrowLeft />
                                        </Link>
                                        &nbsp;{blockNum}&nbsp;
                                        <Link to={`/block/${chainId}/${parseInt(blockNum, 10) + 1}`} className="link">
                                            <MdKeyboardArrowRight />
                                        </Link>
                                        &nbsp;
                                        <CopyButton text={blockNum} />
                                    </div>


                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Status:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3">
                                        <button className="btn btn-outline btn-success btn-xs">Finalized</button>
                                    </div>

                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Block Hash:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3 flex items-center">
                                        <div style={{ maxWidth: '90%' }} className="break-words">
                                            {block.result.block_hash}&nbsp;

                                            <CopyButton text={block.result.block_hash} /></div>
                                    </div>

                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Parent Hash:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3 flex items-center">
                                        <div style={{ maxWidth: '90%' }} className="break-words">
                                            {block.result.parent_hash}&nbsp;

                                            <CopyButton text={block.result.parent_hash} /></div>
                                    </div>
                                    <div className="w-full md:w-1/5 p-3">
                                        <strong>Timestamp:</strong>
                                    </div>
                                    <div className="w-full md:w-4/5 p-3">
                                        {block.result.block_timestamp}
                                    </div>
                                </div>

                            )}
                            {activeTab === "transactions" && (
                                <>
                                    <BlocksTable txs={txs} chainId={chainId} />
                                    <div className="flex justify-between my-10">
                                        <div className="join">
                                            <button className="btn btn-neutral btn-xs join-item" onClick={goToFirstPage} disabled={page <= 1}><MdKeyboardDoubleArrowLeft /></button>
                                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(-1)} disabled={page <= 1}><MdKeyboardArrowLeft /></button>
                                            <input
                                                type="radio"
                                                name="options"
                                                aria-label={page}
                                                className="btn btn-neutral btn-xs btn-outline join-item"
                                            />
                                            <button className="btn btn-neutral btn-xs join-item" onClick={() => changePage(1)} disabled={page >= totalPages}><MdKeyboardArrowRight /></button>
                                            <button className="btn btn-neutral btn-xs join-item" onClick={goToLastPage} disabled={page >= totalPages}><MdKeyboardDoubleArrowRight /></button>
                                        </div>
                                        {txs && txs.length > 0 && <CSVExportButton data={txs} filename="export.csv" />}
                                    </div>
                                </>
                            )}
                            {activeTab === "logs" && logs && logs.length > 0 && (
                                <LogsList logs={logs} chainId={chainId} />
                            )}
                        </>
                    }
                </>
            }
        </>
    );
}

export default BlockDetails;
